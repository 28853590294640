import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
`;

export const BoxSideBar = styled.div`
  z-index: 100;
  width: 280px;
  transition: width 0.3s;
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 30px);
  border-right: 1px solid #edeef3;
  max-height: calc(100vh - 30px);
  overflow: auto;

  &.collapsed {
    width: 50px;
  }
`;

export const BoxContent = styled.div`
  background: #ffffff;
  left: 280px;
  width: calc(100% - 282px);
  height: 100vh;
  position: relative;
  transition: width 0.3s, left 0.3s;

  &.collapsed {
    left: 50px;
    width: calc(100% - 50px);
  }
`;

export const CustomMain = styled.main`
  position: relative;
  top: 60px;
  height: calc(100vh - 60px);
  padding: 2px;
`;
