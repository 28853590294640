import { Route, Routes } from 'react-router-dom';
import PageBranch from '../pages/branch';
import PageDepartment from '../pages/department';
import PageOrganization from '../pages/organization';
import PageUnit from '../pages/unit';
import PrivateRoute from './private-route';
import PageAdmin from '../pages/admin';
import PageAuth from '../pages/auth';
import PageUnauthorized from '../pages/unauthorized';
import PageNotFound from '../pages/not-found';
import PageHelpDesk from '../pages/helpDesk';
import PageCybersecurity from '../pages/cybersecurity';
import Reports from '../pages/reports';
import Models from '../pages/models';
import Uploads from '../pages/uploads';

const AppRoutes = () => (
  <Routes>
    <Route path='/acessar' element={<PageAuth />} />

    <Route
      path='/'
      element={
        <PrivateRoute>
          <PageAdmin />
        </PrivateRoute>
      }
    />

    <Route
      path='/prefeitura/:orgId/'
      element={
        <PrivateRoute>
          <PageOrganization />
        </PrivateRoute>
      }
    />

    <Route
      path='/prefeitura/secretaria/:branchId/'
      element={
        <PrivateRoute>
          <PageBranch />
        </PrivateRoute>
      }
    />

    <Route
      path='/prefeitura/secretaria/unidade/:unitId'
      element={
        <PrivateRoute>
          <PageUnit />
        </PrivateRoute>
      }
    />
    <Route
      path='/prefeitura/secretaria/unidade/departamento/:departmentId'
      element={
        <PrivateRoute>
          <PageDepartment />
        </PrivateRoute>
      }
    />

    <Route
      path='/reports'
      element={
        <PrivateRoute>
          <Reports />
        </PrivateRoute>
      }
    />

    <Route
      path='/models'
      element={
        <PrivateRoute>
          <Models />
        </PrivateRoute>
      }
    />

    <Route
      path='/uploads'
      element={
        <PrivateRoute>
          <Uploads />
        </PrivateRoute>
      }
    />

    <Route
      path='/help-desk'
      element={
        <PrivateRoute>
          <PageHelpDesk />
        </PrivateRoute>
      }
    />

    <Route
      path='/cybersecurity'
      element={
        <PrivateRoute>
          <PageCybersecurity />
        </PrivateRoute>
      }
    />

    <Route
      path='/sem-permissao'
      element={
        <PrivateRoute>
          <PageUnauthorized />
        </PrivateRoute>
      }
    />

    <Route path='*' element={<PageNotFound />} />
  </Routes>
);

export default AppRoutes;
