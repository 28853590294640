import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Subtitle from '../page/subtitle';
import Stats from '../stats';
import Graph from '../graph';

const InfoData = ({ compliance, riskMatrix }) => {
  const data = [
    riskMatrix?.qtdLowRisk,
    riskMatrix?.qtdMediumRisk,
    riskMatrix?.qtdHighRisk,
    riskMatrix?.qtdLowRisk > 0 ||
    riskMatrix?.qtdMediumRisk > 0 ||
    riskMatrix?.qtdHighRisk > 0
      ? riskMatrix?.qtdUndefined
      : 100,
  ];

  return (
    <Row>
      <Col md={7}>
        <Subtitle label='Compliance' />
        <Stats
          progress={compliance?.progress}
          maturity={compliance?.maturity}
        />
      </Col>
      <Col md={5}>
        <Subtitle label='Matriz de Risco' />
        <Graph values={data} />
      </Col>
    </Row>
  );
};

export default InfoData;
