import styled from 'styled-components';
import { ModalBody } from 'react-bootstrap';

export const CustomModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
`;
