import React, { useContext, Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Button,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { ContextUser } from '../../../providers/ContextUser';
import Item from './item';
import {
  CustonDropdownItem,
  CustonDropdownIcon,
  CustonDropdownText,
  AccordionItem,
  AccordionButton,
  AccordionBody,
  CustomButton,
  StyledNav,
  StyledUl,
  StyledModalHeader,
  StyledModalFooter,
} from './styles';

import { AiOutlineDashboard } from 'react-icons/ai';
import { IoBusinessOutline } from 'react-icons/io5';
import { TbReportAnalytics } from 'react-icons/tb';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { GoUpload, GoTasklist } from 'react-icons/go';
import { LuLogOut } from 'react-icons/lu';
import { MdOutlineSlowMotionVideo, MdOutlineSecurity } from 'react-icons/md';

const Navigation = ({ isCollapsed, toggleSidebar }) => {
  const { handleLogout, currentOrganization } = useContext(ContextUser);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState('Vídeo');
  const [hoveredItem, setHoveredItem] = useState(0);
  const [activeEventKey, setActiveEventKey] = useState(null);

  const buildNavigationMenu = () => {
    const menuItens = [
      {
        title: 'Dashboard',
        path: '/',
        icon: <AiOutlineDashboard />,
        isActive: true,
        position: 1,
      },
      {
        title: 'Treinamentos',
        path: '/',
        icon: <MdOutlineSlowMotionVideo />,
        isActive: true,
        isTraining: true,
        position: 8,
      },
      {
        title: 'Sair',
        path: 'logout',
        icon: <LuLogOut />,
        isLogout: true,
        position: 9,
      },
    ];

    if (currentOrganization) {
      const organizationMenuItens = [
        {
          title: 'Secretarias',
          path: `/prefeitura/${currentOrganization.id}`,
          icon: <IoBusinessOutline />,
          isActive: true,
          position: 2,
        },
        // {
        //   title: 'Relatórios',
        //   path: '/reports',
        //   icon: <TbReportAnalytics />,
        //   isActive: true,
        //   position: 3,
        // },
        {
          title: 'Service Desk',
          path: '/help-desk',
          icon: <GoTasklist />,
          isActive: true,
          position: 4,
        },
        {
          title: 'Cybersecurity',
          path: '/cybersecurity',
          icon: <MdOutlineSecurity />,
          isActive: true,
          position: 5,
        },
        {
          title: 'Modelos',
          path: '/models',
          icon: <HiOutlineDocumentDuplicate />,
          isActive: true,
          position: 6,
        },
        {
          title: 'Documentos',
          path: '/uploads',
          icon: <GoUpload />,
          isActive: true,
          position: 7,
        },
      ];

      menuItens.push(...organizationMenuItens);
    }

    menuItens.sort((a, b) => a.position - b.position);

    return menuItens;
  };

  const handleShow = (url) => {
    const videoId = url.split('v=')[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    setVideoUrl(embedUrl);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const action = (path) => {
    if (path === 'logout') {
      handleLogout();
    } else if (path.startsWith('https://www.youtube.com')) {
      handleShow(path);
    } else {
      navigate(path);
    }
  };

  const CustomToggle = ({
    children,
    eventKey,
    isHovered,
    setHoveredItem,
    setActiveEventKey,
    activeEventKey,
    index,
    isCollapsed,
    toggleSidebar,
  }) => {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <CustomButton
        type='button'
        onClick={() => {
          setHoveredItem(index);
          setActiveEventKey(activeEventKey === index ? null : index);
          decoratedOnClick();
          if (isCollapsed && activeEventKey !== index) toggleSidebar();
        }}
        isHovered={isHovered}
        isCollapsed={isCollapsed}
      >
        {children}
      </CustomButton>
    );
  };

  const handleToggle = (index) => {
    setHoveredItem(index);
    setActiveEventKey(index === hoveredItem ? null : index);
  };

  useEffect(() => {
    if (isCollapsed && activeEventKey !== null) {
      setActiveEventKey(null);
    }
  }, [isCollapsed]);

  const renderTooltip = (text) => (props) =>
    (
      <Tooltip id='button-tooltip' {...props}>
        {text}
      </Tooltip>
    );

  return (
    <>
      <StyledNav>
        <StyledUl isCollapsed={isCollapsed}>
          {buildNavigationMenu().map((item, index) => (
            <Fragment key={index}>
              {item.isTraining ? (
                <OverlayTrigger
                  placement='right'
                  overlay={renderTooltip(item.title)}
                >
                  <li>
                    <Accordion activeKey={activeEventKey}>
                      <AccordionItem>
                        <AccordionButton>
                          <CustomToggle
                            eventKey={index.toString()}
                            isHovered={hoveredItem === index}
                            setHoveredItem={setHoveredItem}
                            setActiveEventKey={setActiveEventKey}
                            activeEventKey={activeEventKey}
                            index={index}
                            isCollapsed={isCollapsed}
                            toggleSidebar={toggleSidebar}
                          >
                            {item.icon}
                            {!isCollapsed && <span>{item.title}</span>}
                          </CustomToggle>
                        </AccordionButton>
                        <Accordion.Collapse eventKey={index}>
                          <AccordionBody>
                            <CustonDropdownItem
                              href='#'
                              onClick={() => {
                                setVideoTitle('LGPD nas entidades públicas');
                                handleShow(
                                  'https://www.youtube.com/watch?v=WmSYGCcQ6J0'
                                );
                              }}
                            >
                              <CustonDropdownIcon />
                              <CustonDropdownText>
                                LGPD nas entidades públicas
                              </CustonDropdownText>
                            </CustonDropdownItem>
                            <CustonDropdownItem
                              href='#'
                              onClick={() => {
                                setVideoTitle('Matriz de Risco');
                                handleShow(
                                  'https://www.youtube.com/watch?v=B0QHBXsDQNg'
                                );
                              }}
                            >
                              <CustonDropdownIcon />
                              <CustonDropdownText>
                                Matriz de Risco
                              </CustonDropdownText>
                            </CustonDropdownItem>
                          </AccordionBody>
                        </Accordion.Collapse>
                      </AccordionItem>
                    </Accordion>
                  </li>
                </OverlayTrigger>
              ) : (
                <Item
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                  isActive={item.isActive}
                  isLogout={item.isLogout}
                  action={action}
                  isHovered={hoveredItem === index}
                  onClick={() => handleToggle(index)}
                  isCollapsed={isCollapsed}
                />
              )}
            </Fragment>
          ))}
        </StyledUl>
      </StyledNav>

      <Modal id='modal-video' show={showModal} onHide={handleClose} size='lg'>
        <StyledModalHeader id='modal-video-header' closeButton>
          <Modal.Title>{videoTitle}</Modal.Title>
        </StyledModalHeader>
        <Modal.Body id='modal-video-body'>
          <iframe
            id='modal-video-iframe'
            width='100%'
            height='400px'
            src={videoUrl}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            style={{ borderRadius: '5px' }}
          ></iframe>
        </Modal.Body>
        <StyledModalFooter style={{ padding: '2px 16px' }}>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
        </StyledModalFooter>
      </Modal>
    </>
  );
};

export default Navigation;
