import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/layout/breadcrumbs';
import Page from '../../components/page';
import Org from '../../components/single-org';
import { api } from '../../providers/apiClient';
import { ContextUser } from '../../providers/ContextUser';
import { ContextSearch } from '../../providers/ContextSearch';
import SkeletonLoading from '../../components/skeleton-loading';
import Search from '../../components/search';
import NoResults from '../../components/no-result';
import { OrgCardsContainer } from './styles';

const PageAdmin = ({ isCollapsed }) => {
  const navigate = useNavigate();
  const { handleUpdateCurrentOrganization } = useContext(ContextUser);
  const { searchValues, setSearchValues } = useContext(ContextSearch);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [types, setTypes] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [searchOrgResult, setSearchOrgResult] = useState([]);

  const states = [
    { code: '', name: 'Busque por UF...' },
    { code: 'AC', name: 'Acre' },
    { code: 'AL', name: 'Alagoas' },
    { code: 'AP', name: 'Amapá' },
    { code: 'AM', name: 'Amazonas' },
    { code: 'BA', name: 'Bahia' },
    { code: 'CE', name: 'Ceará' },
    { code: 'DF', name: 'Distrito Federal' },
    { code: 'ES', name: 'Espírito Santo' },
    { code: 'GO', name: 'Goiás' },
    { code: 'MA', name: 'Maranhão' },
    { code: 'MT', name: 'Mato Grosso' },
    { code: 'MS', name: 'Mato Grosso do Sul' },
    { code: 'MG', name: 'Minas Gerais' },
    { code: 'PA', name: 'Pará' },
    { code: 'PB', name: 'Paraíba' },
    { code: 'PR', name: 'Paraná' },
    { code: 'PE', name: 'Pernambuco' },
    { code: 'PI', name: 'Piauí' },
    { code: 'RJ', name: 'Rio de Janeiro' },
    { code: 'RN', name: 'Rio Grande do Norte' },
    { code: 'RS', name: 'Rio Grande do Sul' },
    { code: 'RO', name: 'Rondônia' },
    { code: 'RR', name: 'Roraima' },
    { code: 'SC', name: 'Santa Catarina' },
    { code: 'SP', name: 'São Paulo' },
    { code: 'SE', name: 'Sergipe' },
    { code: 'TO', name: 'Tocantins' },
  ];

  useEffect(() => {
    api
      .get(`/users/orgs`)
      .then((r) => {
        setOrgs(r.data.orgs);
        setSearchOrgResult(r.data.orgs);
        setBreadcrumbs(r.data.breadcrumbs);
        handleUpdateCurrentOrganization(r.data.organization);
        fetchTypes();
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  function fetchTypes() {
    api
      .get('/auth/types')
      .then((r) => {
        const types = r.data;

        const newElement = {
          id: 0,
          description: 'Busque por tipo...',
          level: 0,
        };
        types.unshift(newElement);
        setTypes(types);
      })
      .catch(() => {});
  }

  const handleNavigate = (item) => {
    navigate(`/prefeitura/${item.id}`);
  };

  return (
    <>
      <Breadcrumbs id='breadcrumbs-admin' path={breadcrumbs} />
      <Page id='page-admin' title='Admin'>
        <Search
          id='search-admin'
          data={orgs}
          fields={['name', 'responsible.name', 'responsible.email']}
          placeholder='Busque por prefeitura ou dados do responsável...'
          setSearchResult={setSearchOrgResult}
          onSearchTextChange={setSearchValues}
          pageName='admin'
          states={states}
          types={types}
        />

        {searchOrgResult.length > 0 ? (
          <Row id='org-cards'>
            {searchOrgResult?.map((item) => (
              <Col
                style={{ marginBottom: '20px'}}
                md={isCollapsed ? 3 : 4}
                key={item.id}
                onClick={() => handleNavigate(item)}
              >
                <Org item={item} />
              </Col>
            ))}
          </Row>
        ) : (
          <OrgCardsContainer id='org-cards'>
            {!isFetching && (
              <NoResults
                id='no-results-admin'
                message='Nenhuma prefeitura encrontrada'
                searchText={searchValues['admin']}
              />
            )}
          </OrgCardsContainer>
        )}

        {isFetching && (
          <Row id='org-cards-skeleton'>
            {[0, 1, 2].map((item) => (
              <Col key={item} md={isCollapsed ? 3 : 4} className='mb-4'>
                <SkeletonLoading height='250px' />
              </Col>
            ))}
          </Row>
        )}
      </Page>
    </>
  );
};

export default PageAdmin;
