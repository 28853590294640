import React, { useState, useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Page from '../../components/page';
import Department from '../../components/single-department';
import { api } from '../../providers/apiClient';
import Breadcrumbs from '../../components/layout/breadcrumbs';
import { ContextUser } from '../../providers/ContextUser';
import { ContextSearch } from '../../providers/ContextSearch';
import InfoData from '../../components/info-data';
import Search from '../../components/search';
import NoResults from '../../components/no-result';

const PageUnit = ({ isCollapsed }) => {
  const { handleUpdateCurrentOrganization } = useContext(ContextUser);
  const { searchValues, setSearchValues } = useContext(ContextSearch);
  const { unitId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [unit, setUnit] = useState();
  const [searchDepResult, setSearchDepResult] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    api
      .get(`/units/${unitId}/departments`)
      .then((r) => {
        setDepartments(r.data.departments);
        setSearchDepResult(r.data.departments);
        setUnit(r.data);
        setBreadcrumbs(r.data.breadcrumbs);
        handleUpdateCurrentOrganization(r.data.organization);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  return (
    <>
      <Breadcrumbs path={breadcrumbs} />
      <Page title={unit && unit.name}>
        <InfoData compliance={unit?.compliance} riskMatrix={unit?.riskMatrix} />
        <Search
          data={departments}
          fields={['name', 'responsible.name', 'responsible.email']}
          placeholder='Busque por departamento ou dados do responsável...'
          setSearchResult={setSearchDepResult}
          onSearchTextChange={setSearchValues}
          pageName='unit'
        />
        <Row>
          {searchDepResult.length > 0
            ? searchDepResult.map((item) => (
                <Col md={isCollapsed ? 4 : 6}  key={item.id}>
                  <Department item={item} />
                </Col>
              ))
            : !isFetching && (
                <NoResults
                  message='Nenhum departamento encrontrado'
                  searchText={searchValues || ''}
                />
              )}
        </Row>
      </Page>
    </>
  );
};

export default PageUnit;
