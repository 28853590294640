import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  row-gap: 10px;
  column-gap: 5px;
  flex-wrap: wrap;
`;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  row-gap: 10px;
  column-gap: 5px;
  flex-wrap: wrap;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 140px);
`;

export const Loader = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  i {
    font-size: 50px;
  }
`;

export const Subtitle = styled.div`
  font-size: 15px;
  color: #718a9f;
  font-weight: bold;
`;

export const HorizontalSplit = styled.div`
  border-bottom: 1px solid #ebebeb;
  width: 100%;
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 215px);
`;
