import React, { useContext, useEffect, useState, useRef } from 'react';
import { api } from '../../providers/apiClient';
import { ContextUser } from '../../providers/ContextUser';
import Page from '../../components/page';
import CardFile from '../../components/card-file';
import { ImSpinner4 } from 'react-icons/im';
import { toast } from 'react-toastify';
import NoResults from '../../components/no-result';
import {
  Button,
  Col,
  Row,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import {
  Container,
  DocumentContainer,
  LoadingContainer,
  Loader,
  HorizontalSplit,
  NoResultsContainer,
} from './styles';

const Uploads = () => {
  const { currentOrganization } = useContext(ContextUser);
  const [loading, setLoading] = useState(true);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchOrgDocs();
  }, []);

  async function fetchOrgDocs() {
    setLoading(true);
    const orgId = currentOrganization.id;

    await api
      .get(`/orgs/${orgId}/documents`)
      .then((r) => {
        setUploadFiles(r.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileUpload(file);
    }
  };

  const handleUpload = async () => {
    const orgId = currentOrganization.id;

    if (!fileUpload) {
      return;
    }
    const formData = new FormData();
    formData.append('file', fileUpload);

    try {
      const response = await api.post(`/orgs/${orgId}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadFiles([
        ...uploadFiles,
        { name: fileUpload.name, url: response.data.url },
      ]);

      toast.success('Upload realizado com sucesso');

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      toast.error('Erro ao realizar upload');
    }
  };

  return (
    <Page id='page-documents' title={'Documentos'}>
      {loading ? (
        <LoadingContainer id='loading-container'>
          <Loader id='loader'>
            <i>
              <ImSpinner4 style={{ animation: 'spin 2s infinite linear' }} />
            </i>
          </Loader>
        </LoadingContainer>
      ) : (
        <Container id='container'>
          <InputGroup
            className='mt-3'
            style={{ width: '60%', margin: '0 !important' }}
          >
            <FormControl
              type='file'
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
            <Button variant='primary' onClick={handleUpload}>
              Enviar
            </Button>
          </InputGroup>

          <HorizontalSplit />
          <DocumentContainer id='document-container'>
            {uploadFiles.length > 0
              ? uploadFiles.map((doc) => (
                  <CardFile
                    id={`card-file-${doc.id}`}
                    key={doc.url}
                    doc={doc}
                  />
                ))
              : !loading && (
                  <NoResultsContainer id='no-results-container'>
                    <NoResults
                      id='no-results'
                      message='Nenhum documento encrontrado'
                      searchText=''
                    />
                  </NoResultsContainer>
                )}
          </DocumentContainer>
        </Container>
      )}
    </Page>
  );
};

export default Uploads;
