import React from 'react';
import styled from 'styled-components';
import noResultsImg from '../../assets/imgs/noResults.png';

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyMsg = styled.h2`
  color: #999999;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const NoResults = ({ message, searchText }) => (
  <EmptyContainer>
    <img src={noResultsImg} alt='noResults' />
    <EmptyMsg>
      {message}
      {searchText === '' ? (
        ''
      ) : (
        <span>
          {' '}
          com a palavra {' '}
          <strong style={{ fontWeight: 'bold' }}>
            &quot;{searchText}&quot;
          </strong>
        </span>
      )}
      .
    </EmptyMsg>
  </EmptyContainer>
);

export default NoResults;
