import React from 'react';
import styled from 'styled-components';
import ComplianceLabel from '../compliance-label';
import { expirationMessage } from '../../utils';
import enumQuestionResponseStatus from '../../enum/enumQuestionResponseStatus';

const Element = styled.div`
  background: #eee;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #f6f6f6;
    outline: 1px solid #eee;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const ContainerProgress = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ExpirationMessageBox = styled.strong`
  font-size: 12px;
  margin-top: 5px;
`;

const Question = ({ item }) => (
  <Element>
    <h1>{item.question_details?.short_description}</h1>
    <ContainerProgress>
      <ComplianceLabel status={item.response?.id} activeBC />
      {item.response?.id !== enumQuestionResponseStatus.NOT_ANSWERED &&
        item.response?.id === enumQuestionResponseStatus.YES && (
          <ExpirationMessageBox>
            {expirationMessage(item.expiresAt)}
          </ExpirationMessageBox>
        )}
    </ContainerProgress>
  </Element>
);

export default Question;
