import React from 'react';
import { fileIcons } from '../../providers/file-extension-icons';
import { Container, CardBox, CardFileTypeImage } from './styles';

const CardFile = ({ doc }) => {
  const { url, name } = doc;
  const extension = name.split('.').pop().toLowerCase() || 'file';
  const iconSrc = fileIcons[extension]
    ? fileIcons[extension].icon
    : fileIcons.file.icon;

  const handleDownload = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  function toTitleCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return (
    <Container
      id='card-file-container'
      onClick={() => handleDownload(url, name)}
    >
      <CardBox id='card-box' key={url}>
        <CardFileTypeImage src={iconSrc} alt={`${extension} file icon`} />
      </CardBox>
      <span
        style={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '95%',
          textAlign: 'center',
        }}
        title={toTitleCase(name)}
      >
        {toTitleCase(name)}
      </span>
    </Container>
  );
};

export default CardFile;
