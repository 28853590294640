import React, { useState } from 'react';
import styled from 'styled-components';
import ProgressLabel from '../progress-label';
import OrgImage from '../org-image';
import ContactData from '../contact-data';

const Element = styled.div`
  text-align: center;
  background: #eee;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #f6f6f6;
    outline: 1px solid #eee;
  }

  img {
    width: 90px;
    height: 110px;
  }

  i {
    font-size: 50px;
    display: block;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }

  small {
    color: #666;
    font-size: 12px;
    margin: 10px 0;
    margin-bottom: 5px;
    display: block;
  }
`;

const ContainerProgress = styled.aside`
  position: absolute;
  right: 15px;
`;

const Org = ({ item }) => (
  <Element>
    <ContainerProgress>
      <ProgressLabel amount={item.compliance.maturity} />
    </ContainerProgress>
    <OrgImage src={item.imageUrl} />
    <h1>{item.name}</h1>
    <small>{item.responsible.name || '< Responsável não definido >'}</small>
    <div>
      {item.responsible.email && (
        <ContactData contact={item.responsible.email} />
      )}
    </div>
  </Element>
);

export default Org;
