import React, {
  createContext,
  useEffect,
  useMemo,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from './apiClient';
import { ContextSearch } from './ContextSearch';
import enumUserRole from '../enum/enumUserRole';

const initialState = {
  isLoading: true,
  signedIn: false,
  currentUser: null,
  currentOrganization: null,
  handleUpdateCurrentOrganization: () => {},
  handleLogin: () => {},
  handleLogout: () => {},
  handleForgot: () => {},
  handleReset: () => {},
  getRedirectUrl: () => {},
};

const tokenName = 'JWT';

export const ContextUser = createContext(initialState);

const UserProvider = ({ children }) => {
  const { reset } = useContext(ContextSearch);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [signedIn, setSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);

  const saveToken = (tokenString) => {
    localStorage.setItem(tokenName, tokenString);
  };

  const removeToken = () => localStorage.removeItem(tokenName);

  const loginRedirectRules = {
    ADMIN: {
      path: '/',
    },
    ACCESS_STAFF: {
      path: '/',
    },
    ACCESS_ORG_LEVEL: {
      path: '/prefeitura',
    },
    ACCESS_BRANCH_LEVEL: {
      path: '/prefeitura/secretaria',
    },
    ACCESS_UNIT_LEVEL: {
      path: '/prefeitura/secretaria/unidade',
    },
    ACCESS_DEPARTMENT_LEVEL: {
      path: '/prefeitura/secretaria/unidade/departamento',
    },
  };

  const getRedirectUrl = (role, allowedId) => {
    if (allowedId === undefined) {
      return '/sem-permissao';
    }

    if (allowedId === 'all') {
      return '/';
    }

    return `${loginRedirectRules[role].path}/${allowedId}`;
  };

  const getMeRoute = () => {
    api
      .get('/auth/me')
      .then((response) => {
        const { user } = response.data;
        setSignedIn(true);

        if (user.passwordResetToken && user.firstAccess) {
          navigate(
            `/acessar?recovery=${user.passwordResetToken}&firstAccess=true`,
          );
        } else {
          navigate(getRedirectUrl(user.role, user.allowedId));
        }

        setCurrentUser(response.data.user);
        setCurrentOrganization(response.data.organization);
      })
      .catch(() => {
        setSignedIn(false);
        setCurrentUser(null);
        removeToken();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLogin = async (credentials) => {
    await api
      .post('/auth/session', credentials)
      .then((response) => {
        const { user } = response.data;
        api.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
        saveToken(user.token);
        setSignedIn(true);
        setCurrentUser(user);

        if (user.passwordResetToken && user.firstAccess) {
          navigate(
            `/acessar?recovery=${user.passwordResetToken}&firstAccess=true`,
          );
        } else {
          navigate(getRedirectUrl(user.role, user.allowedId));
        }
      })
      .catch(() => toast.error('Credenciais não encontradas.'));
  };

  const handleUpdateCurrentOrganization = (organization) => {
    setCurrentOrganization(organization);
  };

  const handleForgot = async (email) => {
    await api
      .post('/auth/forgot_password', { email })
      .then(() => {
        toast.success('Você receberá um e-mail para criar uma nova senha.');
      })
      .catch(() => toast.error('Usuário não encontrado.'));
  };

  const handleReset = async (token, newPassword) => {
    await api
      .post('/auth/reset_password', { token, newPassword })
      .then(() => {
        toast.success(
          'Sua senha foi redefinida com sucesso. Você já pode entrar utilizando a mesma.',
        );
        navigate('/acessar');
      })
      .catch(() => toast.error('Erro ao redefinir senha.'));
  };

  const handleLogout = () => {
    removeToken();
    reset();
    navigate('/acessar');
    ocultarJivoChat();
  };

  function mostrarJivoChat() {
    var jivoWidget = document.querySelector('.globalClass_fb40');
    if (jivoWidget) {
      jivoWidget.style.display = 'inline';
    }
  }

  function ocultarJivoChat() {
    var jivoWidget = document.querySelector('.globalClass_fb40');
    if (jivoWidget) {
      jivoWidget.style.display = 'none';
    }
  }

  useEffect(() => {
    getMeRoute();
  }, []);

  const exportData = useMemo(
    () => ({
      isLoading,
      signedIn,
      currentUser,
      currentOrganization,
      handleUpdateCurrentOrganization,
      handleLogin,
      handleLogout,
      handleForgot,
      handleReset,
      getRedirectUrl,
    }),
    [signedIn, currentUser, isLoading, currentOrganization],
  );

  return (
    <ContextUser.Provider value={exportData}>{children}</ContextUser.Provider>
  );
};

export default UserProvider;
