import styled from 'styled-components';
import { Box } from '@mui/material';

export const CustomBox = styled(Box)`
  max-width: 400px;
  width: 100%;
  background: white;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: -8px 12px 15px -10px rgba(25, 42, 70, 0.08);
`;
