import React, { useContext } from 'react';
import { ContextUser } from '../../../providers/ContextUser';
import OrgImage from '../../org-image';
import Navigation from '../navigation';
import Chat from '../../chat';
import { Container, LogoOrgBox } from './styles';

const SideBar = ({ isCollapsed, toggleSidebar }) => {
  const ctxUser = useContext(ContextUser);

  return (
    <Container id='sidebar' className={isCollapsed ? 'collapsed' : ''}>
      {ctxUser.currentOrganization && (
        <LogoOrgBox id='logo-org' isCollapsed={isCollapsed}>
          <OrgImage
            id='logo-org-img'
            src={ctxUser.currentOrganization.imageUrl}
            alt='Logo Prefeitura'
          />
        </LogoOrgBox>
      )}
      <Navigation id='navigation' isCollapsed={isCollapsed} toggleSidebar={toggleSidebar}/>
      <Chat id='chat' />
    </Container>
  );
};

export default SideBar;
