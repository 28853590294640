import React, { useEffect, useState, useContext } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Button, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TbFileAnalytics } from 'react-icons/tb';
import { ImSpinner4 } from 'react-icons/im';
import Page from '../../components/page';
import Unit from '../../components/single-unit';
import { api } from '../../providers/apiClient';
import Breadcrumbs from '../../components/layout/breadcrumbs';
import { ContextUser } from '../../providers/ContextUser';
import { ContextSearch } from '../../providers/ContextSearch';
import InfoData from '../../components/info-data';
import Search from '../../components/search';
import NoResults from '../../components/no-result';
import {
  generateReportName,
  removeExtentionFileName,
  toTitleCase,
} from '../../utils';
import {
  CustomSelect,
  DocumentContainer,
  HorizontalSplit,
  Subtitle,
  UnitsContainer,
  NotificationQuantity,
  CustomTabTitle,
} from './styles';

moment();

const PageBranch = ({ isCollapsed }) => {
  const { handleUpdateCurrentOrganization } = useContext(ContextUser);
  const { searchValues, setSearchValues } = useContext(ContextSearch);
  const [units, setUnits] = useState([]);
  const [searchUnitResult, setSearchUnitResult] = useState([]);
  const [branch, setBranch] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [fetchingConsolidatedReport, setFetchingConsolidatedReport] =
    useState(false);
  const { branchId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [documentSelected, setDocumentSelected] = useState('');
  const [tab, setTab] = useState('units');
  const [notifications, setNotifications] = useState([]);
  const [notificationsLength, setNotificationsLength] = useState(0);

  useEffect(() => {
    api
      .get(`/branchs/${branchId}/units`)
      .then((r) => {
        setUnits(r.data.units);
        setSearchUnitResult(r.data.units);
        setBranch(r.data);
        setNotifications(r.data.notifications);
        setDocuments(r.data.documents);
        setBreadcrumbs(r.data.breadcrumbs);
        handleUpdateCurrentOrganization(r.data.organization);
        setNotificationsLength(
          r.data.notifications.filter((notification) =>
            [1, 2].includes(notification.status.id)
          ).length
        );
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  const handleExportComplianceReport = async () => {
    setFetchingConsolidatedReport(true);
    toast.success('Gerando relatório, aguarde.');

    api
      .get(`/branchs/${branchId}/report`, {
        responseType: 'blob',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        saveAs(
          file,
          generateReportName(
            'compliance',
            branch.organization.name,
            branch.name
          )
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setFetchingConsolidatedReport(false));
  };

  const ButtonExportCompliance = (
    <Button
      key='btnExportCompliance'
      onClick={handleExportComplianceReport}
      type='button'
      disabled={fetchingConsolidatedReport || units.length <= 0}
      style={{
        display: 'flex',
        alignItems: 'center',
        zIndex: '99',
        fontSize: 13,
      }}
      variant='outline-primary'
    >
      {fetchingConsolidatedReport ? (
        <>
          <i
            style={{
              marginRight: 10,
            }}
          >
            <ImSpinner4 style={{ animation: 'spin 2s infinite linear' }} />
          </i>
          Aguarde
        </>
      ) : (
        <>
          <TbFileAnalytics
            style={{
              fontSize: 20,
              marginRight: 10,
            }}
          />
          Relatório de Pendências LGPD
        </>
      )}
    </Button>
  );

  const handleFileSelection = (event) => {
    setDocumentSelected(event.target.value);

    if (!event.target.value) {
      setDocumentSelected('');
      return;
    }

    const link = document.createElement('a');
    link.href = event.target.value;
    link.setAttribute('download', '');
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredDocs = documents.filter((doc) =>
    doc.name?.includes(' - (DPO)')
  );

  return (
    <>
      <Breadcrumbs path={breadcrumbs} />
      <Page
        title={branch && branch.name}
        sideAction={[ButtonExportCompliance]}
        icon={branch && branch.iconUrl}
      >
        {documents.length > 0 && (
          <DocumentContainer>
            <Subtitle>Documentos</Subtitle>
            <CustomSelect
              value={documentSelected}
              onChange={handleFileSelection}
            >
              <option defaultValue='' value='' disabled selected>
                Selecione um documento para download
              </option>
              {filteredDocs.length > 0 && (
                <>
                  {filteredDocs.map((doc) => (
                    <option value={doc.url} key={doc.url}>
                      {toTitleCase(removeExtentionFileName(doc.name)).replace(
                        / - \(dpo\)/i,
                        ' - (DPO)'
                      )}
                    </option>
                  ))}
                  <option defaultValue='' value='' disabled selected>
                    -------------------------------------------------
                  </option>
                </>
              )}
              {documents
                .filter((doc) => !doc.name?.includes(' - (DPO)'))
                .map((doc) => (
                  <option value={doc.url} key={doc.url}>
                    {toTitleCase(removeExtentionFileName(doc.name))}
                  </option>
                ))}
            </CustomSelect>
          </DocumentContainer>
        )}
        <InfoData
          compliance={branch?.compliance}
          riskMatrix={branch?.riskMatrix}
        />
        <HorizontalSplit />
        <Tabs
          id='controlled-tabs'
          activeKey={tab}
          onSelect={(currentTab) => setTab(currentTab)}
          className='mb-3'
        >
          <Tab eventKey='units' title='Unidades'>
            <UnitsContainer>
              <Search
                data={units}
                fields={['name', 'responsible.name', 'responsible.email']}
                placeholder='Busque por unidade ou dados do responsável...'
                setSearchResult={setSearchUnitResult}
                onSearchTextChange={setSearchValues}
                pageName='branch'
              />
              <Row style={{ width: '100%' }}>
                {searchUnitResult.length > 0
                  ? searchUnitResult.map((item) => (
                      <Col md={isCollapsed ? 4 : 6} key={item.id}>
                        <Unit item={item} />
                      </Col>
                    ))
                  : !isFetching && (
                      <NoResults
                        message='Nenhuma unidade encrontrada'
                        searchText={searchValues || ''}
                      />
                    )}
              </Row>
            </UnitsContainer>
          </Tab>
        </Tabs>
      </Page>
    </>
  );
};

export default PageBranch;
