import DOC from '../assets/extension-file/doc.png';
import DOCX from '../assets/extension-file/docx.png';
import XLS from '../assets/extension-file/xls.png';
import XLSX from '../assets/extension-file/xlsx.png';
import TXT from '../assets/extension-file/txt.png';
import ZIP from '../assets/extension-file/zip.png';
import PDF from '../assets/extension-file/pdf.png';
import PPT from '../assets/extension-file/ppt.png';
import FILE from '../assets/extension-file/file.png';

export const fileIcons = {
  doc: {
    icon: DOC,
  },
  docx: {
    icon: DOCX,
  },
  xls: {
    icon: XLS,
  },
  xlsx: {
    icon: XLSX,
  },
  txt: {
    icon: TXT,
  },
  zip: {
    icon: ZIP,
  },
  pdf: {
    icon: PDF,
  },
  ppt: {
    icon: PPT,
  },
  file: {
    icon: FILE,
  },
};
