import styled from 'styled-components';

export const Element = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;

  img {
    max-width: 170px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
`;
