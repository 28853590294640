import React, { useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Button, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TbFileAnalytics } from 'react-icons/tb';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { ImSpinner4 } from 'react-icons/im';
import InfoData from '../../components/info-data';
import Breadcrumbs from '../../components/layout/breadcrumbs';
import Page from '../../components/page';
import Branch from '../../components/single-branch';
import { api } from '../../providers/apiClient';
import { ContextUser } from '../../providers/ContextUser';
import { ContextSearch } from '../../providers/ContextSearch';
import Search from '../../components/search';
import NoResults from '../../components/no-result';
import { generateReportName } from '../../utils';
import { HorizontalSplit, BranchsContainer } from './styles';

moment();

const PageOrganization = ({ isCollapsed }) => {
  const { orgId } = useParams();
  const { handleUpdateCurrentOrganization } = useContext(ContextUser);
  const { searchValues, setSearchValues } = useContext(ContextSearch);
  const [org, setOrg] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [branches, setBranches] = useState([]);
  const [searchBrancheResult, setSearchBrancheResult] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [fetchingConsolidatedReport, setFetchingConsolidatedReport] =
    useState(false);
  const [fetchingEvolutionReport, setFetchingEvolutionReport] = useState(false);
  const [tab, setTab] = useState('branchs');

  useEffect(() => {
    api
      .get(`/orgs/${orgId}/branchs`)
      .then((r) => {
        setBranches(r.data.branchs);
        setSearchBrancheResult(r.data.branchs);
        setOrg(r.data);
        setBreadcrumbs(r.data.breadcrumbs);
        handleUpdateCurrentOrganization(r.data.organization);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  const handleExportComplianceReport = async () => {
    setFetchingConsolidatedReport(true);
    toast.success('Gerando relatório, aguarde.');

    api
      .get(`/orgs/${orgId}/report`, {
        responseType: 'blob',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });

        saveAs(file, generateReportName('compliance', org.name));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setFetchingConsolidatedReport(false));
  };

  const handleExportEvolutionReport = async () => {
    setFetchingEvolutionReport(true);
    toast.success('Gerando relatório, aguarde.');

    api
      .get(`/orgs/${orgId}/report`, {
        responseType: 'blob',
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        saveAs(file, generateReportName('evolucao', org.name));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setFetchingEvolutionReport(false));
  };

  const ButtonExportCompliance = (
    <Button
      key='btnExportCompliance'
      onClick={handleExportComplianceReport}
      type='button'
      disabled={fetchingConsolidatedReport || branches.length <= 0}
      style={{
        display: 'flex',
        alignItems: 'center',
        zIndex: '99',
        fontSize: 13,
      }}
      variant='outline-primary'
    >
      {fetchingConsolidatedReport ? (
        <>
          <i
            style={{
              marginRight: 10,
            }}
          >
            <ImSpinner4 style={{ animation: 'spin 2s infinite linear' }} />
          </i>
          Aguarde
        </>
      ) : (
        <>
          <TbFileAnalytics
            style={{
              fontSize: 20,
              marginRight: 10,
            }}
          />
          Relatório Compliance
        </>
      )}
    </Button>
  );

  const ButtonExportEvolution = (
    <Button
      key='btnExportEvolution'
      onClick={handleExportEvolutionReport}
      type='button'
      disabled
      style={{
        display: 'flex',
        alignItems: 'center',
        zIndex: '99',
        fontSize: 13,
      }}
      variant='outline-primary'
    >
      {fetchingEvolutionReport ? (
        <>
          <i
            style={{
              marginRight: 10,
            }}
          >
            <ImSpinner4 style={{ animation: 'spin 2s infinite linear' }} />
          </i>
          Aguarde
        </>
      ) : (
        <>
          <IoAnalyticsOutline
            style={{
              fontSize: 20,
              marginRight: 10,
            }}
          />
          Relatório Evolução
        </>
      )}
    </Button>
  );

  return (
    <>
      <Breadcrumbs path={breadcrumbs} />
      <Page
        title={org && org.name}
        sideAction={[ButtonExportCompliance, ButtonExportEvolution]}
      >
        <InfoData compliance={org?.compliance} riskMatrix={org?.riskMatrix} />
        <HorizontalSplit />
        <Tabs
          id='controlled-tabs'
          activeKey={tab}
          onSelect={(currentTab) => setTab(currentTab)}
          className='mb-3'
          style={{
            margin: '0 !important',
          }}
        >
          <Tab eventKey='branchs' title='Secretarias'>
            <BranchsContainer>
              <Search
                data={branches}
                fields={['name', 'responsible.name', 'responsible.email']}
                placeholder='Busque por secretaria ou dados do responsável...'
                setSearchResult={setSearchBrancheResult}
                onSearchTextChange={setSearchValues}
                pageName='org'
              />
              <Row style={{ width: '100%' }}>
                {searchBrancheResult.length > 0
                  ? searchBrancheResult.map((item) => (
                      <Col
                        style={{ marginBottom: '20px', height: 'auto' }}
                        md={isCollapsed ? 3 : 4}
                        key={item.id}
                      >
                        <Branch item={item} />
                      </Col>
                    ))
                  : !isFetching && (
                      <NoResults
                        message='Nenhuma secretaria encrontrada'
                        searchText={searchValues || ''}
                      />
                    )}
              </Row>
            </BranchsContainer>
          </Tab>
        </Tabs>
      </Page>
    </>
  );
};

export default PageOrganization;
