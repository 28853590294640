import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import unorm from 'unorm';
import { IoSearchOutline } from 'react-icons/io5';
import {
  SearchContainer,
  SearchTypeContainer,
  SearchStateContainer,
  CustomSelect,
  CustomForm,
  CustomInputGroup,
  SearchIconBox,
} from './styles';

const currentFilters = JSON.parse(localStorage.getItem('filters') || '{}');

const Search = ({
  data,
  fields,
  placeholder,
  setSearchResult,
  onSearchTextChange,
  pageName,
  states,
  types,
}) => {
  const [searchText, setSearchText] = useState(
    currentFilters[pageName]?.text || ''
  );
  const [selectedState, setSelectedState] = useState(
    currentFilters[pageName]?.state || ''
  );
  const [selectedType, setSelectedType] = useState(
    currentFilters[pageName]?.type || 0
  );

  const handleSearch = () => {
    let filteredData = data;

    if (searchText) {
      const normalizedSearchText = unorm
        .nfd(searchText)
        .replace(/[\u0300-\u036f]/g, '')
        .trim();

      filteredData = data.filter((item) =>
        fields.some((field) =>
          field
            .split('.')
            .reduce((obj, prop) => obj?.[prop], item)
            ?.toString()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(normalizedSearchText.toLowerCase())
        )
      );
    }

    if (selectedState) {
      filteredData = filteredData.filter(
        (item) => item.address.stateCode === selectedState
      );
    }

    if (selectedType) {
      filteredData = filteredData.filter(
        (item) => item.type.id === selectedType
      );
    }

    currentFilters[pageName] = {
      ...currentFilters[pageName],
      type: selectedType,
      state: selectedState,
      text: searchText,
    };

    localStorage.setItem('filters', JSON.stringify(currentFilters));

    setSearchResult(filteredData);
  };

  useEffect(() => {
    handleSearch();
  }, [data, searchText, selectedState, selectedType]);

  return (
    <SearchContainer id='search-container'>
      <CustomForm id='search-box'>
        <CustomInputGroup id='search-input'>
          <SearchIconBox>
            <IoSearchOutline />
          </SearchIconBox>
          <Form.Control
            style={{ caretColor: 'auto' }}
            type='search'
            autoFocus
            placeholder={placeholder}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              onSearchTextChange(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
        </CustomInputGroup>
        {states && (
          <SearchStateContainer id='search-state-container'>
            <CustomSelect
              id='search-state-select'
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              {states.map((state) => (
                <option value={state.code} key={state.code}>
                  {state.name}
                </option>
              ))}
            </CustomSelect>
          </SearchStateContainer>
        )}
        {types && (
          <SearchTypeContainer id='search-type-container'>
            <CustomSelect
              id='search-type-select'
              value={selectedType}
              onChange={(e) => {
                setSelectedType(Number(e.target.value));
              }}
            >
              {types.map((type) => (
                <option value={type.id} key={type.id}>
                  {type.description}
                </option>
              ))}
            </CustomSelect>
          </SearchTypeContainer>
        )}
      </CustomForm>
    </SearchContainer>
  );
};

export default Search;
