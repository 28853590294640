import React from 'react';
import styled from 'styled-components';
import { ImSpinner4 } from 'react-icons/im';

const Element = styled.div`
  background: rgba(${(props) => props.color}, 0.2);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 140px;

  @media (max-width: 765px) {
    margin-bottom: 15px;
  }

  @media (max-width: 1260px) {
    flex-direction: column-reverse;
    justify-content: center;

    div {
      text-align: center;
    }
  }

  &:hover {
    background: rgba(${(props) => props.color}, 0.15);
  }

  i {
    font-size: 60px;
    color: rgba(${(props) => props.color}, 0.4);

    @media (max-width: 1260px) {
      font-size: 50px;
      margin-bottom: 10px;
    }
  }

  span {
    font-size: 45px;
    display: block;
    margin-bottom: 5px;
    color: rgba(${(props) => props.color}, 0.9);

    @media (max-width: 1260px) {
      font-size: 30px;
    }
  }
  abbr {
    color: rgba(${(props) => props.color}, 0.9);
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 1260px) {
      font-size: 16px;
    }
  }
`;

const Loader = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  i {
    font-size: 50px;
  }
`;

const BigNumbers = ({ amount, title, icon, color = '0,0,0' }) => (
  <Element color={color}>
    {amount >= 0 ? (
      <>
        <div>
          <span>{amount}%</span>
          <abbr>{title}</abbr>
        </div>
        <i>{icon}</i>
      </>
    ) : (
      <Loader>
        <i>
          <ImSpinner4 style={{ animation: 'spin 2s infinite linear' }} />
        </i>
      </Loader>
    )}
  </Element>
);

export default BigNumbers;
