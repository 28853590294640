import React, { useContext, useEffect, useState } from 'react';
import { api } from '../../providers/apiClient';
import Page from '../../components/page';
import ModalNewTask from '../../components/modal-task';
import ModalNewTaskView from '../../components/modal-task-view';
import { Button } from 'react-bootstrap';
import {
  normalizeString,
  formatTimestampToDate,
  toUpperCaseClean,
} from '../../utils';
import { MdOutlineAddTask, MdOutlineMenu } from 'react-icons/md';
import { ContextUser } from '../../providers/ContextUser';
import {
  Container,
  CustomTableHead,
  CustomTableBody,
  CustomTableRow,
  CustomTH,
  CustomTD,
  TableBox,
  CustomTable,
  CustomDetailTH,
} from './styles';

const PageHelpDesk = () => {
  const { currentOrganization, currentUser } = useContext(ContextUser);
  const [isFetching, setIsFetching] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isViewModalOpened, setIsViewModalOpened] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);

  const handleToggleModal = () => {
    setIsModalOpened((value) => !value);
  };

  const handleToggleViewModal = () => {
    setIsViewModalOpened((value) => !value);
  };

  const handleViewTask = (task) => {
    setSelectedTask(task);
    handleToggleViewModal();
  };

  useEffect(() => {
    fetchTasks();
    fetchBranchSetup();
  }, []);

  async function fetchTasks() {
    const orgServiceDeskName = toUpperCaseClean(currentOrganization.name);

    await api
      .get(`/service_desk`, {
        params: {
          orgName: orgServiceDeskName,
        },
      })
      .then((response) => {
        setTickets(response.data);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }

  async function fetchBranchSetup() {
    if (currentOrganization) {
      const orgId = currentOrganization.id;
      await api
        .get(`/orgs/${orgId}/branch_setup`)
        .then((r) => {
          setBranchOptions(r.data);
        })
        .catch(() => {})
        .finally(() => setIsFetching(false));
    }
  }

  const buildTableHead = () => {
    const columnHeaders = [
      'Código',
      'Data de Abertura',
      'Assunto',
      'Usuário',
      'Status',
      'Detalhes',
    ];
    return (
      <CustomTableHead id='table-head'>
        <CustomTableRow id='table-head-row'>
          {columnHeaders.map((header, index) =>
            header === 'Detalhes' ? (
              <CustomDetailTH key={index} id={`th-${normalizeString(header)}`}>
                {header}
              </CustomDetailTH>
            ) : (
              <CustomTH key={index} id={`th-${normalizeString(header)}`}>
                {header}
              </CustomTH>
            ),
          )}
        </CustomTableRow>
      </CustomTableHead>
    );
  };

  const buildTableBody = () => {
    return (
      <CustomTableBody id='table-body'>
        {tickets.map((ticket) => (
          <CustomTableRow
            id={`table-body-row-${ticket.caseId}`}
            key={ticket.caseId}
          >
            <CustomTD>{ticket.caseId}</CustomTD>
            <CustomTD>{formatTimestampToDate(ticket.dataAbertura)}</CustomTD>
            <CustomTD>{ticket.resumo}</CustomTD>
            <CustomTD>{ticket.usuario}</CustomTD>
            <CustomTD>{`${ticket.status} - ${ticket.estagio}`}</CustomTD>
            <CustomDetailTH>
              <Button
                variant='outline-primary'
                onClick={() => handleViewTask(ticket)}
              >
                <MdOutlineMenu />
              </Button>
            </CustomDetailTH>
          </CustomTableRow>
        ))}
      </CustomTableBody>
    );
  };

  const ButtonNewTask = (
    <Button
      key='btnAddTask'
      onClick={handleToggleModal}
      type='button'
      style={{
        display: 'flex',
        alignItems: 'center',
        zIndex: '99',
        fontSize: 13,
      }}
      variant='outline-primary'
    >
      <>
        <MdOutlineAddTask
          style={{
            fontSize: 20,
            marginRight: 10,
          }}
        />
        Novo chamado
      </>
    </Button>
  );

  return (
    <>
      <Page title={'Service Desk'} sideAction={[ButtonNewTask]}>
        <Container id='table-container'>
          <TableBox className='table-responsive'>
            <CustomTable striped hover>
              {buildTableHead()}
              {buildTableBody()}
            </CustomTable>
          </TableBox>
        </Container>
      </Page>
      <ModalNewTask
        isModalOpened={isModalOpened}
        handleToggleModal={handleToggleModal}
        fetchTasks={fetchTasks}
        branchOptions={branchOptions}
        organization={currentOrganization}
        user={currentUser}
      />
      {selectedTask && (
        <ModalNewTaskView
          isModalOpened={isViewModalOpened}
          handleToggleModal={handleToggleViewModal}
          task={selectedTask}
        />
      )}
    </>
  );
};

export default PageHelpDesk;
