import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Form, Modal, ModalFooter, Row } from 'react-bootstrap';
import 'react-date-picker/dist/DatePicker.css';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../providers/apiClient';
import SubmitButton from '../form/submit-button';
import Instructions from './instructions';
import StatusResponse from './StatusResponse';
import enumQuestionResponseStatus from '../../enum/enumQuestionResponseStatus';
import { ContextUser } from '../../providers/ContextUser';
import { complianceStatus } from '../../providers/compliance-status';
import { toUpperCaseClean, formatDateToUTC } from '../../utils';
import {
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledDescription,
  StyledContainer,
  StyledRiskFactor,
  StyledFormLabel,
  StyledResponseContainer,
  StyledStrong,
} from './styles.js';

const ModalQuestion = ({
  item,
  isModalOpened,
  handleToggleModal,
  fetchQuestions,
}) => {
  const { currentUser, currentOrganization } = useContext(ContextUser);
  const [requesting, setRequesting] = useState(false);
  const [riskMatrix, setRiskMatrix] = useState(null);
  const [riskMatrixDesc, setRiskMatrixDesc] = useState(null);
  const [showModalData, setShowModalData] = useState(null);
  const [showModalServiceDeskOpen, setShowModalServiceDeskOpen] =
    useState(false);
  const [serviceDeskSugestionDesc, setServiceDeskSugestionDesc] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const sendNewServiceDesk = async (modalData, description) => {
    const { name: userName, phone: userPhone } = currentUser;
    const { name: orgName } = currentOrganization;
    const { question_details, unit, branch, department } = item;

    const serviceDeskPayload = {
      cliente: toUpperCaseClean(orgName),
      secretaria: toUpperCaseClean(branch.name),
      localizacao: `${toUpperCaseClean(unit.name)}/${toUpperCaseClean(
        department.name
      )}`,
      usuario: userName,
      fone: `(${userPhone.ddd})${userPhone.number}`,
      resumo: question_details.short_description,
      descricao: description,
      observacao: modalData.observation,
      dataAbertura: formatDateToUTC(new Date()),
    };

    try {
      await api.post(`/service_desk`, serviceDeskPayload);
      toast.success(`Chamado criado com sucesso!`);
    } catch (error) {
      toast.error('Erro ao registrar novo chamado.');
    }
  };

  const sendResponse = async (data) => {
    const responseData = {
      impact: +"0",
      probability: +"0",
      response: +"4",
      observation: data.observation,
    };

    // const responseData = {
    //   impact: +data.impact,
    //   probability: +data.probability,
    //   response: +data.response,
    //   observation: data.observation,
    // };

    try {
      const response = await api.patch(
        `questions/rel_questions/${data.id}/send_response`,
        responseData
      );
      toast.success(
        `Pergunta respondida! Data de expiração: ${response.data.expiresAt}`
      );
      handleToggleModal();
      fetchQuestions();
    } catch (error) {
      toast.error('Erro ao responder pergunta.');
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    reset(item);
    setValue('response', item?.response?.id || null);
  }, [item]);

  const onSubmit = handleSubmit(async (data) => {
    setShowModalData(data);
    setServiceDeskSugestionDesc(
      item?.question_details?.detail_description || ''
    );
    const isCompliance = data.response === enumQuestionResponseStatus.YES;

    if (!isCompliance) {
      setShowModalServiceDeskOpen(true);
    } else {
      setRequesting(true);
      await sendResponse(data);
    }
  });

  const handleServiceDeskModal = async (
    shouldRegisterServiceDesk,
    finalDescription
  ) => {
    setShowModalServiceDeskOpen(false);
    setRequesting(true);
    if (shouldRegisterServiceDesk) {
      await sendNewServiceDesk(showModalData, finalDescription);
    }
    await sendResponse(showModalData);
  };

  const probabilityList = [
    {
      id: 1,
      desc: 'Baixa: Improvável, ocorrendo apenas em circunstâncias excepcionais.',
    },
    {
      id: 2,
      desc: 'Moderada: Possível, podendo ocorrer ocasionalmente sob certas condições.',
    },
    {
      id: 3,
      desc: 'Alta: Provável, com uma tendência de ocorrer regularmente ou frequentemente.',
    },
  ];

  const impactsList = [
    {
      id: 1,
      desc: 'Baixo: Efeitos perceptíveis, porém limitados e de fácil resolução.',
    },
    {
      id: 2,
      desc: 'Moderado: Efeitos notáveis, causando problemas ou exigindo esforço para resolução.',
    },
    {
      id: 3,
      desc: 'Alto: Efeitos graves ou severos, causando danos e exigindo recursos ou resposta urgente.',
    },
  ];

  const calculateResponse = () => {
    if (riskMatrix) {
      let response = 0;
      let riskMatrixDesc = '';

      if (riskMatrix > 0 && riskMatrix <= 3) {
        response = 3;
        riskMatrixDesc = 'Baixo';
      } else if (riskMatrix >= 4 && riskMatrix <= 6) {
        response = 2;
        riskMatrixDesc = 'Médio';
      } else if (riskMatrix > 7) {
        response = 4;
        riskMatrixDesc = 'Alto';
      }

      setValue('response', response);
      setRiskMatrixDesc(riskMatrixDesc);
    }
  };

  const calculateMatrix = () => {
    setRiskMatrix(watch('probability') * watch('impact'));
  };

  useEffect(() => {
    calculateMatrix();
  }, [watch('probability'), watch('impact')]);

  useEffect(() => {
    calculateResponse();
  }, [riskMatrix]);

  const ModalServiceDesk = ({ modalData, serviceDeskSugestionDesc }) => {
    const response = complianceStatus[modalData?.response];
    const [finalDescription, setFinalDescription] = useState(
      serviceDeskSugestionDesc
    );

    return (
      <Modal
        show={showModalServiceDeskOpen}
        centered
        onHide={() => handleServiceDeskModal(false)}
        size='lg'
      >
        <StyledModalHeader closeButton>Novo chamado!</StyledModalHeader>
        <StyledModalBody>
          <StyledDescription>
            Identificamos que a situação atual dessa questão está como (
            <StyledStrong color={response?.color}>
              {response?.label}
            </StyledStrong>
            ). É recomendado informar o responsável para que as ações corretivas
            necessárias possam ser implementadas.
            <br />
            <br />
            Deseja registrar um novo chamado para o responsável?
          </StyledDescription>
          <Form.Group>
            <Form.Label>Descrição sugerida:</Form.Label>
            <Form.Control
              as='textarea'
              rows={4}
              value={finalDescription}
              onChange={(e) => setFinalDescription(e.target.value)}
              style={{caretColor: 'auto'}}
            />
          </Form.Group>
        </StyledModalBody>
        <ModalFooter className='justify-content-between'>
          <Button
            type='button'
            variant='light'
            onClick={() => handleServiceDeskModal(false, finalDescription)}
          >
            Não
          </Button>
          <Button
            type='button'
            onClick={() => handleServiceDeskModal(true, finalDescription)}
            loading={false}
            block={false}
          >
            Sim, Registrar chamado!
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      <StyledModal
        id='modal-question'
        show={isModalOpened}
        centered
        onHide={handleToggleModal}
        size='xl'
        showModalServiceDeskOpen={showModalServiceDeskOpen}
      >
        <form id='modal-question-form' onSubmit={onSubmit}>
          <StyledModalHeader closeButton>
            {item?.question_details.short_description}
          </StyledModalHeader>
          <StyledModalBody>
            <StyledDescription>
              {item?.question_details.detail_description}
            </StyledDescription>
            <Instructions
              activityDescription={item?.question_details?.activity_description}
              actionHelp={item?.question_details?.action_help}
            />
            <Form.Group>
              <StyledRiskFactor>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Probabilidade:</Form.Label>
                      <Form.Select
                        {...register('probability', {
                          required: true,
                          valueAsNumber: true,
                          validate: (value) => value !== 0,
                        })}
                        isInvalid={errors.probability && true}
                      >
                        {probabilityList.map((i) => (
                          <option key={i.id} value={i.id}>
                            {i.desc}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Impacto:</Form.Label>
                      <Form.Select
                        {...register('impact', {
                          required: true,
                          valueAsNumber: true,
                          validate: (value) => value !== 0,
                        })}
                        isInvalid={errors.impact && true}
                      >
                        {impactsList.map((i) => (
                          <option key={i.id} value={i.id}>
                            {i.desc}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <aside>
                  <Form.Label>Matriz de risco</Form.Label>
                  <span>{riskMatrixDesc || '-'}</span>
                </aside>
              </StyledRiskFactor>
            </Form.Group>
            <Form.Group>
              <Form.Label>Observações:</Form.Label>
              <Form.Control
                style={{ caretColor: 'auto' }}
                as='textarea'
                {...register('observation', {
                  required:
                    watch('response') === enumQuestionResponseStatus.PARTIAL ||
                    watch('response') === enumQuestionResponseStatus.NO,
                })}
                isInvalid={errors.observation && true}
              />
            </Form.Group>
            <StyledResponseContainer>
              <Col md={9}>
                <Controller
                  control={control}
                  name='response'
                  rules={{
                    required: true,
                    min: 2,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <StatusResponse
                      value={value}
                      onChange={onChange}
                      errors={errors}
                    />
                  )}
                />
              </Col>
              <Col md={3}>
                <Form.Group className='mt-3'>
                  <StyledFormLabel>Data de Expiração</StyledFormLabel>
                  <StyledContainer>{item.expiresAt}</StyledContainer>
                </Form.Group>
              </Col>
            </StyledResponseContainer>
          </StyledModalBody>
          <ModalFooter className='justify-content-between'>
            <Button type='button' variant='light' onClick={handleToggleModal}>
              Cancelar
            </Button>
            <SubmitButton type='submit' loading={requesting} block={false}>
              Salvar
            </SubmitButton>
          </ModalFooter>
        </form>
      </StyledModal>

      {showModalServiceDeskOpen && (
        <ModalServiceDesk
          modalData={showModalData}
          serviceDeskSugestionDesc={serviceDeskSugestionDesc}
        />
      )}
    </>
  );
};

export default ModalQuestion;
