import React from 'react';
import styled from 'styled-components';

const Element = styled.div`
  background: white;
  padding: 10px;

  @media (max-width: 550px) {
    min-width: 350px;
  }
`;

const Box = ({ children, style }) => (
  <Element style={style}>{children}</Element>
);

Box.defaultProps = {
  style: undefined,
};

export default Box;
