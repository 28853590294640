import moment from 'moment';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

moment();

export function generateReportName(type, firstName, secondName) {
  const formatWord = function (word) {
    const formattedWord1 =
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    return formattedWord1;
  };

  const formatDate = moment(new Date()).format('DD.MM.YYYY');
  let formattedName = firstName.split(' ').map(formatWord).join('');

  if (secondName) {
    formattedName += `_${secondName.split(' ').map(formatWord).join('')}`;
  }

  const formattedType = formatWord(type);

  return `${formatDate}_${formattedName}_${formattedType}.pdf`;
}

export function expirationMessage(dateString) {
  const currentDate = moment();
  const expirationDate = moment(dateString, 'DD/MM/YYYY');
  const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

  if (daysUntilExpiration < 0) {
    const daysSinceExpiration = -daysUntilExpiration;
    return `Expirou há ${daysSinceExpiration} dias, em ${expirationDate.format(
      'DD/MM/YYYY',
    )}`;
  }

  if (daysUntilExpiration === 0) {
    return 'Expira hoje';
  }
  return `Expira em ${daysUntilExpiration + 1} dias, (${expirationDate.format(
    'DD/MM/YYYY',
  )})`;
}

export const isExpired = (dateString) => {
  const currentDate = moment();
  const expirationDate = moment(dateString, 'DD/MM/YYYY');
  const daysUntilExpiration = expirationDate.diff(currentDate, 'days');

  return daysUntilExpiration < 0;
};

export const getUrlByPath = (list, path) => {
  const doc = list.find((d) => d.fullPath === path);
  return doc ? doc.url : null;
};

export const toTitleCase = (str) => {
  const strFormated = str
    .toLowerCase()
    .replace(/(^|\s)\S/g, (s) => s.toUpperCase());

  return strFormated;
};

export const removeExtentionFileName = (str) => {
  const strFormated = str.split('.').slice(0, -1).join('.');

  return strFormated;
};

export const normalizeString = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-')
    .toLowerCase();
};

export const formatTimestampToDate = (timestamp) => {
  return format(new Date(timestamp), 'dd/MM/yyyy HH:mm');
};

export const toUpperCaseClean = (str) => {
  const removeAccents = (s) => {
    return s.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  let cleanedStr = str.toUpperCase();
  cleanedStr = cleanedStr.replace(/-/g, ' ');
  cleanedStr = cleanedStr.replace(/[^A-Z\s]/g, '');
  cleanedStr = removeAccents(cleanedStr);
  cleanedStr = cleanedStr.replace(/\s+/g, '_');

  return cleanedStr;
};

export const formatDateToUTC = (date) => {
  const formattedUTCDate = formatInTimeZone(
    date,
    'UTC',
    "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
  );
  return formattedUTCDate;
};

// // Obtém a data atual
// const now = new Date();

// // Formata a data atual como ISO 8601
// const isoDate = formatISO(now);

// // Formata a data atual como timestamp
// const timestamp = getTime(now);

// // Converte a data ISO para um objeto Date
// const parsedISODate = parseISO(isoDate);

// // Converte a data ISO para o fuso horário UTC
// const utcDate = toZonedTime(parsedISODate, 'UTC');

// // Converte a data UTC para um fuso horário específico (São Paulo)
// const zonedDate = toZonedTime(utcDate, 'America/Sao_Paulo');

// // Formata a data no fuso horário de São Paulo
// const formattedZonedDate = formatInTimeZone(
//   zonedDate,
//   'America/Sao_Paulo',
//   'yyyy-MM-dd HH:mm:ssXXX',
//   { locale: ptBR }
// );

// // Exemplos de uso do `date-fns` e `date-fns-tz`
// console.log('now:', now);
// console.log('iso:', isoDate);
// console.log('timestamp:', timestamp);
// console.log('parsedISODate:', parsedISODate);
// console.log('utcDate:', utcDate);
// console.log('zonedDate:', zonedDate);
// console.log('formattedZonedDate:', formattedZonedDate);

// // Outros formatos de data usando `date-fns`
// const formattedDate1 = format(now, 'dd/MM/yyyy HH:mm:ss');
// const formattedDate2 = format(now, 'MMMM do, yyyy H:mm:ss a');

// console.log('formattedDate1:', formattedDate1);
// console.log('formattedDate2:', formattedDate2);

// // Formata a data ISO como UTC
// const formattedUTCDate = formatInTimeZone(
//   parsedISODate,
//   'UTC',
//   'yyyy-MM-ddHH:mm:ss.SSSXXX'
// );
// console.log('formattedUTCDate:', formattedUTCDate);

// // Converte a data para o fuso horário de Nova York
// const nyDate = toZonedTime(utcDate, 'America/New_York');
// const formattedNYDate = formatInTimeZone(
//   nyDate,
//   'America/New_York',
//   'yyyy-MM-dd HH:mm:ss.|SSSXXX'
// );
// console.log('formattedNYDate:', formattedNYDate);
