import styled from 'styled-components';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  &.modal {
    filter: ${({ showModalServiceDeskOpen }) =>
      showModalServiceDeskOpen ? 'brightness(80%)' : 'none'};
    backdrop-filter: ${({ showModalServiceDeskOpen }) =>
      showModalServiceDeskOpen ? 'brightness(80%)' : 'none'};
  }
`;

export const StyledModalBody = styled(ModalBody)`
  padding: 10px 15px;
  * label {
    margin-bottom: 3px;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  padding: 10px 15px;
`;

export const StyledDescription = styled.div`
  background: #f6f6f6;
  border: 1px solid #ccc;
  color: #666;
  font-size: 14px;
  padding: 10px 15px;
  margin-bottom: 10px;
  line-height: 20px;
  max-height: 150px;
  border-radius: 5px;
  overflow: auto;
  white-space: pre-line;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const StyledRiskFactor = styled.section`
  display: flex;
  justify-content: space-between;

  div {
    flex-grow: 1;
  }

  aside {
    margin-left: 16px;
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;

    label {
      font-size: 16px;
      min-height: 20px;
      margin-top: 10px;
    }

    span {
      background: #e9e9e9;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #004d8d !important;
      min-height: 70px;
      font-size: 32px;
      border-radius: 5px;
    }
  }

  select {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;

export const StyledFormLabel = styled(Form.Label)`
  font-weight: bold;
`;

export const StyledResponseContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledStrong = styled.strong`
  color: ${(props) => props.color};
  font-weight: bold;
`;
