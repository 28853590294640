import React, { useEffect, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'react-bootstrap';
import { CustomModalBody } from '../modal-task/styles';

const ModalViewTask = ({ isModalOpened, handleToggleModal, task }) => {
  const descricaoRef = useRef(null);
  const observacaoRef = useRef(null);

  useEffect(() => {
    if (descricaoRef.current) {
      descricaoRef.current.style.height = 'auto';
      descricaoRef.current.style.height = `${descricaoRef.current.scrollHeight}px`;
    }
    if (observacaoRef.current) {
      observacaoRef.current.style.height = 'auto';
      observacaoRef.current.style.height = `${observacaoRef.current.scrollHeight}px`;
    }
  }, [task.descricao, task.observacao]);
  const getLocationPart = (location, part) => {
    if (location) {
      const parts = location.split('/');

      if (part === 'unit') {
        return parts[0];
      } else if (part === 'department') {
        return parts[1];
      } else {
        console.log('Invalid part specified');
      }
    }

    return '';
  };

  return (
    <Modal
      id='modal-task'
      show={isModalOpened}
      centered
      onHide={handleToggleModal}
      size='lg'
    >
      <ModalHeader id='modal-task-header' closeButton>
        Detalhes do Chamado
      </ModalHeader>
      <CustomModalBody id='modal-task-body'>
        <Row>
          <Col md={12}>
            <Form.Group id='view-secretaria'>
              <Form.Label>Secretaria:</Form.Label>
              <Form.Control
                type='text'
                value={task.secretaria}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group id='view-unidade'>
              <Form.Label>Unidade:</Form.Label>
              <Form.Control
                type='text'
                value={getLocationPart(task.localizacao, 'unit')}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group id='view-departamento'>
              <Form.Label>Departamento:</Form.Label>
              <Form.Control
                type='text'
                value={getLocationPart(task.localizacao, 'department')}
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Descrição</Form.Label>
          <Form.Control
            as='textarea'
            ref={descricaoRef}
            rows='1'
            value={task.descricao}
            readOnly
            disabled
            style={{ overflow: 'hidden', resize: 'none' }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Observação</Form.Label>
          <Form.Control
            as='textarea'
            ref={observacaoRef}
            rows='4'
            value={task.observacao}
            readOnly
            disabled
            style={{ overflow: 'hidden', resize: 'none' }}
          />
        </Form.Group>
      </CustomModalBody>
      <ModalFooter>
        <Button variant='light' onClick={handleToggleModal}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalViewTask;
