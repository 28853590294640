import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(-3px + 100%);
`;

export const CustomIFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;
