import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ProgressLabel from '../progress-label';
import ContactData from '../contact-data';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Element = styled.div`
  text-align: center;
  background: #eee;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #f6f6f6;
    outline: 1px solid #eee;
  }

  img {
    width: 50%;
  }

  i {
    font-size: 50px;
    display: block;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  small {
    color: #666;
    font-size: 12px;
    margin: 10px 0;
    margin-bottom: 5px;
    display: block;
  }
`;

const CustomTooltip = styled(Tooltip)`
  .tooltip-arrow::before {
    border-right-color: #737373 !important;
  }
  .tooltip-inner {
    background-color: #737373 !important;
  }
`;

const ContainerProgress = styled.aside`
  position: absolute;
  right: 15px;
`;

const Branch = ({ item }) => {
  const notificationsLength = item.notifications.filter((notification) =>
    [1, 2].includes(notification.statusId),
  ).length;
  const notifactionTooltipLabel =
    notificationsLength > 1 ? 'Notificações' : 'Notificação';
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/prefeitura/secretaria/${id}`);
  };

  const NotificationTooltip = (
    <CustomTooltip id='tooltip'>
      <strong>{notifactionTooltipLabel}</strong>
    </CustomTooltip>
  );

  return (
    <Element onClick={() => handleNavigate(item.id)}>
      {notificationsLength > 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IoIosNotificationsOutline
            style={{
              color: '#f16024',
              fontSize: '27px',
              position: 'absolute',
            }}
          />
          <OverlayTrigger placement='right' overlay={NotificationTooltip}>
            <strong
              style={{
                position: 'relative',
                fontSize: '12px',
                color: 'white',
                width: '20px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#737373',
                borderRadius: '25px',
                textAlign: 'center',
                padding: '0 15px',
                left: '17px',
                top: '-5px',
              }}
            >
              {notificationsLength > 99 ? '99+' : notificationsLength}
            </strong>
          </OverlayTrigger>
        </div>
      )}

      <ContainerProgress>
        <ProgressLabel amount={item.compliance.maturity} />
      </ContainerProgress>
      <img src={item.iconUrl} alt='' />
      {item?.type?.id === 3 && <span>Secretaria de</span>}
      <h1>{item.name}</h1>
      <small>{item.responsible.name || '< Responsável não definido >'}</small>
      <div>
        {item.responsible.email && (
          <ContactData contact={item.responsible.email} />
        )}
      </div>
    </Element>
  );
};

export default Branch;
