import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import logo from '../../assets/imgs/logo-horizontal.png';
import Forgot from './forgot';
import Login from './login';
import Recover from './reset';
import { Element } from './styles';

const PageAuth = () => {
  const [action, setAction] = useState('login');
  const [params] = useSearchParams();

  const recoveryToken = params.get('recovery') || null;
  const firstAccess = params.get('firstAccess') === 'true' || false;

  const handleAction = (param) => {
    setAction(param);
  };

  const render = () => {
    if (recoveryToken) {
      return <Recover token={recoveryToken} firstAccess={firstAccess} />;
    }
    if (action === 'forgot') {
      return <Forgot handleAction={handleAction} />;
    }

    return <Login handleAction={handleAction} />;
  };

  return (
    <Element>
      <a href='https://karaok3.com'>
        <img className='logo' src={logo} alt='' />
      </a>
      {render()}
    </Element>
  );
};

export default PageAuth;
