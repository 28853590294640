import React, { useContext } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import { LuLogOut } from 'react-icons/lu';
import { ContextUser } from '../../../providers/ContextUser';

import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
} from 'react-icons/tb';
import {
  Container,
  ProfileBox,
  CustomProfileImg,
  CustomDropdownToggle,
  LogoBox,
  CustomDropdownMenu,
  ToggleButtonBox,
  ToggleButton,
} from './styles';
import logoAdaptep from '../../../assets/imgs/logo-horizontal.png';

const Header = ({ isCollapsed, toggleSidebar }) => {
  const ctxUser = useContext(ContextUser);

  const { handleLogout } = ctxUser;

  const renderTooltip = (expandText, collapseText) => (props) =>
    (
      <Tooltip id='button-tooltip' {...props}>
        {isCollapsed ? expandText : collapseText}
      </Tooltip>
    );

  if (ctxUser === null) {
    return <>carregando</>;
  }

  return (
    <Container id='header-container'>
      <LogoBox id='logo'>
        <ToggleButtonBox>
          <OverlayTrigger
            placement='right'
            overlay={renderTooltip('Expandir', 'Recolher')}
          >
            <ToggleButton onClick={toggleSidebar}>
              {isCollapsed ? (
                <TbLayoutSidebarLeftExpand size={35} />
              ) : (
                <TbLayoutSidebarLeftCollapse size={35} />
              )}
            </ToggleButton>
          </OverlayTrigger>
        </ToggleButtonBox>
        <img id='logo-img' src={logoAdaptep} alt='Logo ADAPTEP' />
      </LogoBox>
      <ProfileBox>
        <div>
          <h4>{ctxUser?.currentUser?.name}</h4>
          <span>{ctxUser?.currentUser?.email}</span>
        </div>

        <Dropdown>
          <CustomDropdownToggle variant='ligth'>
            <CustomProfileImg
              src={ctxUser?.currentUser?.imageUrl}
              alt='Imagem Usuário'
            />
          </CustomDropdownToggle>

          <CustomDropdownMenu>
            <Dropdown.Item href='#/' disabled>
              <span>
                <MdOutlinePhotoCamera /> Foto de perfil
              </span>
              <span>
                <CiEdit /> Alterar senha
              </span>
            </Dropdown.Item>
            <Dropdown.Item href='#'>
              <span onClick={handleLogout}>
                <LuLogOut /> Sair
              </span>
            </Dropdown.Item>
          </CustomDropdownMenu>
        </Dropdown>
      </ProfileBox>
    </Container>
  );
};

export default Header;
