import React, { useContext, useEffect, useState } from 'react';
import { api } from '../../providers/apiClient';
import { ContextUser } from '../../providers/ContextUser';
import Page from '../../components/page';
import CardFile from '../../components/card-file';
import { ImSpinner4 } from 'react-icons/im';
import NoResults from '../../components/no-result';
import {
  DocumentContainer,
  LoadingContainer,
  Loader,
  NoResultsContainer,
} from './styles';

const Models = () => {
  const { currentOrganization } = useContext(ContextUser);
  const [loading, setLoading] = useState(true);
  const [documentTemplates, setDocumentTemplates] = useState([]);

  useEffect(() => {
    fetchOrgModels();
  }, []);

  async function fetchOrgModels() {
    setLoading(true);
    const orgId = currentOrganization.id;

    await api
      .get(`/orgs/${orgId}/models`)
      .then((r) => {
        setDocumentTemplates(r.data);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }

  return (
    <Page id='page-models' title={'Modelos'}>
      {loading ? (
        <LoadingContainer id='loading-container'>
          <Loader id='loader'>
            <i>
              <ImSpinner4 style={{ animation: 'spin 2s infinite linear' }} />
            </i>
          </Loader>
        </LoadingContainer>
      ) : (
        <DocumentContainer id='document-container'>
          {documentTemplates.length > 0
            ? documentTemplates.map((doc) => (
                <CardFile id={`card-file-${doc.id}`} key={doc.url} doc={doc} />
              ))
            : !loading && (
                <NoResultsContainer id='no-results-container'>
                  <NoResults
                    id='no-results'
                    message='Nenhum modelo encrontrado'
                    searchText=''
                  />
                </NoResultsContainer>
              )}
        </DocumentContainer>
      )}
    </Page>
  );
};

export default Models;
