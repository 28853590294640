import React from 'react';
import styled from 'styled-components';
import { qualityRules } from '../../providers/quality-rules';

const Element = styled.span`
  background: ${(props) => props.chosenRule.background};
  color: ${(props) => props.chosenRule.color};
  padding: 3px 10px;
  border-radius: 15px;
  font-weight: bold;
`;

const ProgressLabel = ({ amount }) => {
  const rule = qualityRules.filter(
    (item) => amount >= item.from && amount <= item.to,
  )[0];

  return <Element chosenRule={rule}>{amount}%</Element>;
};

export default ProgressLabel;
