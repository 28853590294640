export const qualityRules = [
  {
    from: 0,
    to: 25,
    label: 'Péssimo',
    background: '#ffb2b2',
    color: '#ff3634',
  },
  {
    from: 25,
    to: 50,
    label: 'Ruim',

    background: '#fbcfbd',
    color: '#f16024',
  },
  {
    from: 50,
    to: 75,
    label: 'Bom',

    background: '#bdd4f8',
    color: '##1f6fe8',
  },
  {
    from: 75,
    to: 100,
    label: 'Ótimo',

    background: '#bae6be',
    color: '#22af30',
  },
];
