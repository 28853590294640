import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { api } from '../../providers/apiClient';
import SubmitButton from '../form/submit-button';
import { CustomModalBody } from './styles';
import { formatDateToUTC, toUpperCaseClean } from '../../utils';

const getValidationSchema = (unitOptions, departmentOptions) =>
  Yup.object().shape({
    secretaria: Yup.string().required('Secretaria obrigatória'),
    unidade: Yup.string().test('unidade', 'Unidade obrigatória', (value) =>
      unitOptions.length > 0 ? !!value : true,
    ),
    departamento: Yup.string().test(
      'departamento',
      'Departamento obrigatório',
      (value) => (departmentOptions.length > 0 ? !!value : true),
    ),
    resumo: Yup.string().required('Assunto obrigatório'),
    descricao: Yup.string().required('Descrição obrigatória'),
  });

const ModalNewTask = ({
  isModalOpened,
  handleToggleModal,
  fetchTasks,
  branchOptions,
  organization,
  user,
}) => {
  const [requesting, setRequesting] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const validationSchema = getValidationSchema(unitOptions, departmentOptions);

  const handleClose = (formik) => {
    formik.resetForm();
    handleToggleModal();
    setUnitOptions([]);
    setDepartmentOptions([]);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setRequesting(true);

    const payload = {
      cliente: toUpperCaseClean(organization.name),
      secretaria: values.secretaria,
      localizacao: `${values.unidade}/${values.departamento}`,
      usuario: user.name,
      fone: `(${user.phone?.ddd})${user.phone?.number}`,
      resumo: values.resumo,
      descricao: values.descricao,
      observacao: values.observacao,
      dataAbertura: formatDateToUTC(new Date()),
    };

    await api
      .post(`/service_desk`, payload)
      .then((response) => {
        toast.success('Chamado criado com sucesso!');
        handleToggleModal();
        resetForm();
        setUnitOptions([]);
        setDepartmentOptions([]);
        fetchTasks();
      })
      .catch(() => {
        toast.error('Erro ao criar chamado.');
      })
      .finally(() => setRequesting(false));
  };

  async function fetchUnitSetup(branchId) {
    await api
      .get(`/branchs/${branchId}/unit_setup`)
      .then((r) => {
        setUnitOptions(r.data);
      })
      .catch(() => {})
      .finally(() => {});
  }

  async function fetchDepartmentSetup(unit_id) {
    await api
      .get(`/units/${unit_id}/department_setup`)
      .then((r) => {
        setDepartmentOptions(r.data);
      })
      .catch(() => {})
      .finally(() => {});
  }

  return (
    <Formik
      initialValues={{
        secretaria: '',
        unidade: '',
        departamento: '',
        resumo: '',
        descricao: '',
        observacao: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Modal
          id='modal-task'
          show={isModalOpened}
          centered
          onHide={() => handleClose(formik)}
          size='lg'
        >
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader id='modal-task-header' closeButton>
              Novo Chamado
            </ModalHeader>
            <CustomModalBody id='modal-task-body'>
              <Row>
                <Col md={4}>
                  <Form.Group id='select-secretaria'>
                    <Form.Label>Secretaria:</Form.Label>
                    <Form.Select
                      {...formik.getFieldProps('secretaria')}
                      isInvalid={
                        formik.touched.secretaria && formik.errors.secretaria
                      }
                      onChange={(event) => {
                        const selectedOption =
                          event.target.options[event.target.selectedIndex];
                        const branchId = selectedOption.getAttribute('data-id');
                        setUnitOptions([]);
                        setDepartmentOptions([]);
                        formik.setFieldError('unidade', '');
                        formik.setFieldTouched('unidade', false);
                        formik.setFieldValue('unidade', '');
                        formik.setFieldError('departamento', '');
                        formik.setFieldTouched('departamento', false);
                        formik.setFieldValue('departamento', '');
                        if (branchId) {
                          fetchUnitSetup(branchId);
                        }
                        formik.handleChange(event);
                      }}
                    >
                      <option value=''>Selecione...</option>
                      {branchOptions.map((option) => (
                        <option
                          key={option.id}
                          value={toUpperCaseClean(option.name)}
                          data-id={option.id}
                        >
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.secretaria}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group id='select-unidade'>
                    <Form.Label>Unidade:</Form.Label>
                    <Form.Select
                      disabled={unitOptions.length === 0}
                      {...formik.getFieldProps('unidade')}
                      isInvalid={
                        formik.touched.unidade && formik.errors.unidade
                      }
                      onChange={(event) => {
                        const selectedOption =
                          event.target.options[event.target.selectedIndex];
                        const unit_id = selectedOption.getAttribute('data-id');
                        setDepartmentOptions([]);
                        formik.setFieldError('departamento', '');
                        formik.setFieldTouched('departamento', false);
                        formik.setFieldValue('departamento', '');
                        if (unit_id) {
                          fetchDepartmentSetup(unit_id);
                        }
                        formik.handleChange(event);
                      }}
                    >
                      <option value=''>Selecione...</option>
                      {unitOptions.map((option) => (
                        <option
                          key={option.id}
                          value={toUpperCaseClean(option.name)}
                          data-id={option.id}
                        >
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.unidade}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group id='select-departamento'>
                    <Form.Label>Departamento:</Form.Label>
                    <Form.Select
                      disabled={departmentOptions.length === 0}
                      {...formik.getFieldProps('departamento')}
                      isInvalid={
                        formik.touched.departamento &&
                        formik.errors.departamento
                      }
                    >
                      <option value=''>Selecione...</option>
                      {departmentOptions.map((option) => (
                        <option
                          key={option.id}
                          value={toUpperCaseClean(option.name)}
                        >
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.departamento}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label>Assunto</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='1'
                  style={{ caretColor: 'auto', resize: 'none' }}
                  {...formik.getFieldProps('resumo')}
                  isInvalid={formik.touched.resumo && formik.errors.resumo}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.resumo}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  style={{ caretColor: 'auto', resize: 'none' }}
                  rows='4'
                  as='textarea'
                  {...formik.getFieldProps('descricao')}
                  isInvalid={
                    formik.touched.descricao && formik.errors.descricao
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.descricao}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Observação</Form.Label>
                <Form.Control
                  style={{ caretColor: 'auto', resize: 'none' }}
                  rows='2'
                  as='textarea'
                  {...formik.getFieldProps('observacao')}
                  isInvalid={
                    formik.touched.observacao && formik.errors.observacao
                  }
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.observacao}
                </Form.Control.Feedback>
              </Form.Group>
            </CustomModalBody>
            <ModalFooter className='justify-content-between'>
              <Button
                type='button'
                variant='light'
                onClick={() => handleClose(formik)}
              >
                Cancelar
              </Button>
              <SubmitButton type='submit' loading={requesting} block={false}>
                Salvar
              </SubmitButton>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default ModalNewTask;
