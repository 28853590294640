import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

const CustomButton = styled(Button)`
  ${(props) => {
    if (props.$fullwidth) {
      return css`
        width: 100%;
      `;
    }
    return '';
  }}
`;

function SubmitButton({
  children,
  loading = false,
  block = false,
  type = 'submit',
  waitingMessage = 'carregando',
  fullwidth = false,
  callFunc = null,
}) {
  return (
    <CustomButton
      disabled={loading}
      block={block.toString()}
      onClick={callFunc}
      type={type}
      $fullwidth={fullwidth}
    >
      {!loading ? children : `${waitingMessage}...`}
    </CustomButton>
  );
}

export default SubmitButton;
