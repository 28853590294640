export const complianceStatus = {
  2: {
    label: 'Parcial Compliance',
    icon: 'bx bx-confused',
    background: '#bdd4f8',
    color: '#1f6fe8',
  },
  3: {
    label: 'Compliance',
    icon: 'bx bx-happy',
    background: '#bae6be',
    color: '#22af30',
  },
  4: {
    label: 'Não Compliance',
    icon: 'bx bx-sad',
    background: '#ffb2b2',
    color: '#ff3634',
  },
};
