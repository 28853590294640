import styled from 'styled-components';
import { complianceStatus } from '../../providers/compliance-status';
import enumQuestionResponseStatus from '../../enum/enumQuestionResponseStatus';

const Element = styled.span`
  color: ${(props) => props.variant.color};
  padding: 5px 15px;
  border-radius: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.variant.color};
  background: ${(props) => (props.active ? props.variant.background : 'none')};
  min-width: 186px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    display: none;
  }

  span {
    font-size: 14px;
  }

  i {
    margin-right: 10px;
    font-size: 20px;
  }
`;

const ComplianceLabel = ({ status, activeBC }) => {
  if (status !== enumQuestionResponseStatus.NOT_ANSWERED) {
    return (
      <Element variant={complianceStatus[status]} active={activeBC}>
        <i className={complianceStatus[status].icon} />
        <span>{complianceStatus[status].label}</span>
      </Element>
    );
  }

  return (
    <div
      style={{
        color: '#aaa',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 12,
      }}
    >
      Pendente
    </div>
  );
};

export default ComplianceLabel;
