import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 195px;
`;

export const CardBox = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

export const CardFileTypeImage = styled.img`
  width: 80%;
  height: 80%;
`;
