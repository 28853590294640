import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './app';
import { GlobalStyle } from './app/globalStyles';
import UserProvider from './providers/ContextUser';
import SearchProvider from './providers/ContextSearch';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <BrowserRouter>
      <SearchProvider>
        <UserProvider>
          <GlobalStyle />
          <ToastContainer />
          <App />
        </UserProvider>
      </SearchProvider>
    </BrowserRouter>
  </StrictMode>,
);
