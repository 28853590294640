import 'react-toastify/dist/ReactToastify.css';
import './custom-bootstrap-colors.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './app-routes';
import { GlobalStyle } from './globalStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito, sans-serif',
  },
});

function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
