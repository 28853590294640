import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const Container = styled.div`
  z-index: 100;
  left: 0;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #ebebeb;

  @media (max-width: 550px) {
    flex-direction: column-reverse;
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  span {
    display: block;
    margin-bottom: 0px;
  }
`;

export const ProfileBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 280px);
  height: 60px;
  padding-left: 10px;
`;

export const CustomProfileImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
`;

export const CustomDropdownToggle = styled(Dropdown.Toggle)`
  border: none;
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-right: 1px solid #ebebeb;
  width: 280px;
  height: 60px;
  gap: 25px;

  img {
    width: 150px;
  }
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  position: absolute !important;
  inset: -1px 5px auto auto !important;
  /* Outros estilos personalizados */
  background-color: #fff; /* Exemplo de cor de fundo */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Exemplo de sombra */
  border-radius: 4px; /* Exemplo de borda arredondada */
  padding: 10px; /* Exemplo de padding */
  z-index: 1000; /* Exemplo de z-index */
`;

export const ToggleButtonBox = styled.div`
  margin-top: 15px;
`;


export const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  color: #a1a1a1;

  &:hover {
    color: #004d8d;
    font-weight: bold;

    svg {
      font-size: 40px;
      transition: font-size 0.3s ease;
    }
  }
`;
