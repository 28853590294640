import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Element = styled.ul`
  min-height: 36px;
  min-width: 350px;
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 10px;

  li {
    margin-right: 15px;

    a {
      color: #999;

      &[href=''] {
        text-decoration: none;
      }
    }

    &:after {
      content: '/';
      margin-left: 15px;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: '';
      }
    }
  }
`;

const Breadcrumbs = ({ path }) => {
  const urls = {
    admin: '/',
    org: '/prefeitura',
    branch: '/prefeitura/secretaria',
    unit: '/prefeitura/secretaria/unidade',
    department: '/prefeitura/secretaria/unidade/departamento',
  };

  const getUrl = (item) => {
    if (item.key !== 'admin') {
      return `${urls[item?.key]}/${item.id}`;
    }
    return '/';
  };

  return (
    <Element>
      {path.map((item) => (
        <li key={item.label}>
          <Link to={getUrl(item)}>
            <span style={{ textTransform: 'capitalize' }}>{item.label}</span>
          </Link>
        </li>
      ))}
    </Element>
  );
};

export default Breadcrumbs;
