import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ContactData from '../contact-data';
import ProgressLabel from '../progress-label';

const Element = styled.div`
  text-align: center;
  background: #eee;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #f6f6f6;
    outline: 1px solid #eee;
  }

  i {
    font-size: 50px;
    display: block;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  h1 {
    font-size: 20px;
    font-weight: 600;
  }

  small {
    color: #666;
    font-size: 12px;
    margin: 10px 0;
    display: block;
  }
`;
const ContainerProgress = styled.aside`
  display: inline-block;
  margin-bottom: 15px;
`;

const Unit = ({ item }) => {
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/prefeitura/secretaria/unidade/${id}`);
  };

  return (
    <Element onClick={() => handleNavigate(item.id)}>
      <ContainerProgress>
        <ProgressLabel amount={item.compliance.maturity} />
      </ContainerProgress>
      <img src={item.iconUrl} alt='' />
      <h1>{item.name}</h1>
      <small>{item.responsible.name}</small>
      <div>
        {item.responsible.email && (
          <ContactData contact={item.responsible.email} />
        )}
      </div>
    </Element>
  );
};

export default Unit;
