import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FieldErrorsImpl } from 'react-hook-form';
import styled from 'styled-components';
import ComplianceLabel from '../../compliance-label';
import ButtonYoutube from '../ButtonYoutube';

const status = [
  { id: 2, label: 'Parcial Compliance' },
  { id: 3, label: 'Compliance' },
  { id: 4, label: 'Não Compliance' },
];

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const StatusResponse = ({ value, onChange, errors }) => (
  <Form.Group
    className='mt-3'
    style={{
      borderRadius: '5px',
      padding: '5px',
      border: errors.response ? '1px solid #dc3545' : 0,
    }}
  >
    <Form.Label>Status</Form.Label>
    <Container>
      {status.map((item) => (
        <div key={item.id}>
          <div>
            <ComplianceLabel status={item.id} activeBC={value === item.id} />
          </div>
        </div>
      ))}
    </Container>
  </Form.Group>
);

export default StatusResponse;
