import React from 'react';
import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

const Element = styled.div`
  line-height: 18px;
  font-size: 13px;
  color: #666;
  white-space: pre-line;
`;

const CustomAccordionTitle = styled.h2`
  color: #475f7b;
`;

const CustomAccordionHeader = styled(Accordion.Header)`
  button {
    padding: 8px;
    font-size: 14px;
  }
`;

const CustomAccordionBody = styled(Accordion.Body)`
  background-color: #f6f6f6;
`;

const Instructions = ({ activityDescription, actionHelp }) => (
  <div className='mt-0 mb-3'>
    <Accordion>
      <Accordion.Item eventKey='0'>
        <CustomAccordionHeader>
          <CustomAccordionTitle>Entenda a atividade.</CustomAccordionTitle>
        </CustomAccordionHeader>
        <CustomAccordionBody>
          <Element>{activityDescription}</Element>
        </CustomAccordionBody>
      </Accordion.Item>
      <Accordion.Item eventKey='1'>
        <CustomAccordionHeader>
          <CustomAccordionTitle>O que e como fazer?</CustomAccordionTitle>
        </CustomAccordionHeader>
        <CustomAccordionBody>
          <Element>{actionHelp}</Element>
        </CustomAccordionBody>
      </Accordion.Item>
    </Accordion>
  </div>
);

export default Instructions;
