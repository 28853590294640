import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/layout/breadcrumbs';
import ModalQuestion from '../../components/modal-question';
import Page from '../../components/page';
import Question from '../../components/single-question';
import { api } from '../../providers/apiClient';
import { ContextUser } from '../../providers/ContextUser';
import { ContextSearch } from '../../providers/ContextSearch';
import InfoData from '../../components/info-data';
import Search from '../../components/search';
import NoResults from '../../components/no-result';

const PageDepartment = ({ isCollapsed }) => {
  const { handleUpdateCurrentOrganization } = useContext(ContextUser);
  const { searchValues, setSearchValues } = useContext(ContextSearch);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { departmentId } = useParams();
  const handleToggleModal = () => {
    if (isModalOpened) {
      setActiveQuestion(null);
    }
    setIsModalOpened((value) => !value);
  };
  const [questions, setQuestions] = useState([]);
  const [department, setDepartment] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [searchQuestionResult, setSearchQuestionResult] = useState([]);

  const fetchQuestions = () => {
    api
      .get(`/departments/${departmentId}/questions`)
      .then((r) => {
        setDepartment(r.data);
        setQuestions(r.data.questions);
        setSearchQuestionResult(r.data.questions);
        setBreadcrumbs(r.data.breadcrumbs);
        handleUpdateCurrentOrganization(r.data.organization);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    if (!isModalOpened) {
      fetchQuestions();
    }
  }, [isModalOpened]);

  return (
    <>
      <Breadcrumbs path={breadcrumbs} />
      <Page title={department && department.name}>
        <InfoData
          compliance={department?.compliance}
          riskMatrix={department?.riskMatrix}
        />
        <Search
          data={questions}
          fields={[
            'question_details.short_description',
            'question_details.detail_description',
            'question_details.activity_description',
            'question_details.action_help',
          ]}
          placeholder='Busque por questões...'
          setSearchResult={setSearchQuestionResult}
          onSearchTextChange={setSearchValues}
          pageName='dep'
        />
        <Row>
          {searchQuestionResult.length > 0
            ? searchQuestionResult.map((item) => (
                <Col
                  md={12}
                  key={item.id}
                  onClick={() => {
                    setActiveQuestion(item);
                    handleToggleModal();
                  }}
                >
                  <Question item={item} />
                </Col>
              ))
            : !isFetching && (
                <NoResults
                  message='Nenhuma questão encrontrada'
                  searchText={searchValues || ''}
                />
              )}
        </Row>
        {activeQuestion && (
          <ModalQuestion
            item={activeQuestion}
            isModalOpened={isModalOpened}
            handleToggleModal={handleToggleModal}
            fetchQuestions={fetchQuestions}
          />
        )}
      </Page>
    </>
  );
};

export default PageDepartment;
