import React, { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import SubmitButton from '../../../components/form/submit-button';
import Box from '../../../components/layout/box';
import { ContextUser } from '../../../providers/ContextUser';
import { CustomBox } from './styles';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';

const Forgot = ({ handleAction }) => {
  const [requesting, setRequesting] = useState(false);
  const [fields, setFields] = useState({ email: '' });

  const { handleForgot } = useContext(ContextUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequesting(true);
    handleForgot(fields.email).finally(() => setRequesting(false));
  };

  return (
    <>
      <CustomBox>
        <div>
          <form onSubmit={handleSubmit} action=''>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel htmlFor='email'>Seu email</InputLabel>
              <OutlinedInput
                id='email'
                type='email'
                name='email'
                value={fields.email}
                onChange={handleChange}
                label='Seu email'
                style={{ caretColor: 'auto' }}
              />
            </FormControl>
            <SubmitButton
              type='submit'
              loading={requesting}
              block={false}
              fullwidth
            >
              Recuperar Senha
            </SubmitButton>
          </form>
        </div>
      </CustomBox>
      <Button
        style={{
          textTransform: 'none',
        }}
        onClick={() => {
          handleAction('login');
        }}
      >
        Retornar ao login
      </Button>
    </>
  );
};

export default Forgot;
