import styled from 'styled-components';
import { Table } from 'react-bootstrap';

export const Container = styled.div``;

export const TableBox = styled.div`
  border: 1px solid #ebebeb;
  overflow-x: auto;
`;

export const CustomTableHead = styled.thead`
  border-bottom: 2px solid #ebebeb;
`;

export const CustomTableBody = styled.tbody``;

export const CustomTableRow = styled.tr``;

export const CustomTH = styled.th`
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #dee2e6;
`;

export const CustomTD = styled.td`
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
`;

export const CustomTable = styled(Table)`
  margin: 0;
  min-width: 100%;
`;

export const CustomDetailTH = styled(CustomTH)`
  width: auto;
  white-space: nowrap;
  text-align: right;
`;
