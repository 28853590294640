import React, { useState } from 'react';
import Header from './header';
import SideBar from './side-bar';
import { Container, BoxSideBar, BoxContent, CustomMain } from './styles';

const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Container id='layout-container'>
      <BoxSideBar id='sidebar-box' className={isCollapsed ? 'collapsed' : ''}>
        <SideBar id='sidebar-component' isCollapsed={isCollapsed} toggleSidebar={toggleSidebar}/>
      </BoxSideBar>
      <BoxContent id='content-box' className={isCollapsed ? 'collapsed' : ''}>
        <Header id='header-component' isCollapsed={isCollapsed} toggleSidebar={toggleSidebar}/>
        <CustomMain id='main-component'>
        {React.cloneElement(children, { isCollapsed })}
        </CustomMain>
      </BoxContent>
    </Container>
  );
};
export default Layout;
