import styled from 'styled-components';

export const Container = styled.div`
  height: calc(-60px + 100vh);
  background: white;

  @media (max-width: 550px) {
    min-width: 350px;
  }
`;

export const LogoOrgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  border-bottom: 1px solid #ebebeb;

  img {
    width: ${(props) => (props.isCollapsed ? '40px' : '79px')};
    transition: width 0.3s ease;
  }
`;
