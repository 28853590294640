import styled from 'styled-components';
import { Form, InputGroup } from 'react-bootstrap';

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const SearchStateContainer = styled.div`
  flex: 1;
`;

export const SearchTypeContainer = styled.div`
  flex: 1;
`;

export const CustomSelect = styled(Form.Select)`
  padding-left: 15px;
  border-radius: 50px !important;
  color: #a7a7a7;
  font-style: italic;
`;

export const CustomForm = styled(Form)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 10px;

  .form-control {
    padding-left: 32px;
    border-radius: 50px !important;
    ::placeholder {
      color: #a7a7a7;
      font-style: italic;
    }
  }
`;

export const CustomInputGroup = styled(InputGroup)`
  flex: 2;
`;

export const SearchIconBox = styled.div`
  position: absolute;
  left: 10px;
  top: 6px;
  z-index: 5;
  color: #a7a7a7;
  font-size: 20px;
`;
