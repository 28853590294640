import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  ChatBox,
  Header,
  InputTextarea,
  Message,
  MessageBubble,
  CloseButton,
} from './styles';
import { api } from '../../providers/apiClient';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const chatBoxRef = useRef(null);

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const newMessage = { role: 'user', content: input };
    setMessages([...messages, newMessage]);
    setInput('');

    const payload = { message: input };

    try {
      const response = await api.post('/chatbot', payload);
      const botMessage = { role: 'assistant', content: response.data.reply };
      setMessages([...messages, newMessage, botMessage]);
    } catch (error) {
      console.error('Erro na API', error.response.data.error);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <Container id='chat-container' isOpen={isOpen}>
      <Header
        id='chat-header'
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span>Fale com a AdaptAI...</span>
        <CloseButton onClick={() => setIsOpen(false)}>X</CloseButton>
      </Header>
      <>
        <ChatBox id='chat-box' ref={chatBoxRef} isOpen={isOpen}>
          {messages.map((msg, index) => (
            <Message key={index} isUser={msg.role === 'user'}>
              <MessageBubble isUser={msg.role === 'user'}>
                {msg.content}
              </MessageBubble>
            </Message>
          ))}
        </ChatBox>
        <InputTextarea
          id='chat-input'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder='Digite aqui...'
          rows='2'
        />
      </>
    </Container>
  );
};

export default Chat;
