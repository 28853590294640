import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Page = styled.section`
  text-align: center;
  padding: 50px 0;

  code {
    font-size: 80px;
    color: #004d8d;
    font-weight: bold;
  }
  div {
    font-size: 30px;
    font-weight: bold;
    margin: 30px 0 15px;
  }
  p {
    margin-bottom: 30px;
  }
`;

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <code>404</code>
      <p>A página que você está procurando não foi encontrada.</p>
      <Button
        onClick={() => {
          navigate('/');
        }}
      >
        Volta ao início
      </Button>
    </Page>
  );
};

export default PageNotFound;
