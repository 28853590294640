import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 1px;
  width: 279px;
  max-height: ${(props) => (props.isOpen ? `calc(100vh - 60px)` : `30px`)};
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  transition: max-height 0.3s ease-in-out;
  z-index: 101;
  border: 1px solid #ccc;
  background: #e4e4e4;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: #004d8d;
  color: #ffffff;
  cursor: pointer;
  width: 277px;
`;

export const ChatBox = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 5px;
  margin: 1px;
`;

export const Message = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

export const MessageBubble = styled.div`
  max-width: 95%;
  padding: 5px;
  background: ${(props) => (props.isUser ? '#004d8d' : '#ffffff')};
  color: ${(props) => (props.isUser ? '#ffffff' : '#004d8d')};

  border-radius: ${(props) =>
    props.isUser ? '10px 10px 0 10px' : '10px 10px 10px 0'};

  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const InputTextarea = styled.textarea`
  border: 1px solid #ccc;
  padding: 8px;
  resize: none;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  outline: none;
  border-right: none;
  border-left: none;
  color: #004d8d;
  caret-color: auto;
  margin: 10px 5px 5px 5px;
  border-radius: 5px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
`;
