import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';

const ContainerContact = styled.strong`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: center;
  height: 15px;

  i {
    font-size: 16px !important;
    margin-left: 5px;
  }
`;

const handleCopy = (e, text) => {
  e.stopPropagation();

  toast.info(() => (
    <>
      Copiado: <strong style={{ fontWeight: 'bold' }}>{text}</strong>
    </>
  ));
  return navigator.clipboard.writeText(text);
};

const renderTooltip = (props) => (
  <Tooltip id='button-tooltip' {...props}>
    Copiar contato
  </Tooltip>
);

const ContactData = ({ contact }) => (
  <ContainerContact>
    <strong>{contact}</strong>
    <OverlayTrigger
      placement='right'
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <i className='bx bx-copy' onClick={(e) => handleCopy(e, contact)} />
    </OverlayTrigger>
  </ContainerContact>
);

export default ContactData;
