import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiRocket, BiBarChart } from 'react-icons/bi';
import BigNumbers from './big-numbers';

const Stats = ({ progress, maturity }) => (
  <Row style={{ marginTop: '10px' }}>
    <Col md={6}>
      <BigNumbers
        amount={progress}
        title='Progresso'
        color='90,140,200'
        icon={<BiBarChart />}
      />
    </Col>
    <Col md={6}>
      <BigNumbers
        amount={maturity}
        title='Maturidade'
        color='40,167,69'
        icon={<BiRocket />}
      />
    </Col>
  </Row>
);

export default Stats;
