import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StyledElement } from './styles.js';

function Item({
  title,
  path,
  icon,
  isActive,
  isLogout,
  action,
  isHovered,
  onClick,
  isCollapsed,
}) {
  const handleClick = () => {
    action(path);
    onClick();
  };

  const renderTooltip = (text) => (props) =>
    (
      <Tooltip id='button-tooltip' {...props}>
        {text}
      </Tooltip>
    );

  return (
    <StyledElement
      isActive={isActive}
      isLogout={isLogout}
      isHovered={isHovered}
      isCollapsed={isCollapsed}
    >
      <OverlayTrigger placement='right' overlay={renderTooltip(title)}>
        <button
          id='navigation-item'
          type='button'
          onClick={handleClick}
          disabled={!isActive && !isLogout}
        >
          {icon}
          {!isCollapsed && <span>{title}</span>}
        </button>
      </OverlayTrigger>
    </StyledElement>
  );
}

export default Item;
