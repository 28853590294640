import React, { useState, useContext } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { ContextUser } from '../../../providers/ContextUser';
import SubmitButton from '../../../components/form/submit-button';
import { CustomBox } from './styles';

const Login = ({ handleAction }) => {
  const [warning, setWarning] = useState();
  const [requesting, setRequesting] = useState(false);
  const [fields, setFields] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(ContextUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequesting(true);
    handleLogin(fields).finally(() => setRequesting(false));
  };

  return (
    <>
      <CustomBox>
        <div>
          <form onSubmit={handleSubmit} action=''>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel htmlFor='email'>Seu email</InputLabel>
              <OutlinedInput
                id='email'
                type='email'
                name='email'
                value={fields.email}
                onChange={handleChange}
                label='Seu email'
                style={{ caretColor: 'auto' }}
              />
            </FormControl>
            <FormControl fullWidth margin='normal' variant='outlined'>
              <InputLabel htmlFor='password'>Senha</InputLabel>
              <OutlinedInput
                id='password'
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={fields.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Senha'
                style={{ caretColor: 'auto' }}
              />
            </FormControl>
            <SubmitButton
              type='submit'
              loading={requesting}
              block={false}
              fullwidth
            >
              Entrar
            </SubmitButton>
          </form>
        </div>
      </CustomBox>
      <Button
        style={{
          textTransform: 'none',
        }}
        onClick={() => {
          handleAction('forgot');
        }}
      >
        Esqueci minha senha
      </Button>
    </>
  );
};

export default Login;
