import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
`;

export const CustomSelect = styled(Form.Select)`
  padding: 2px 0px 2px 10px;
  border-radius: 50px !important;
  color: #a7a7a7;
  font-style: italic;
`;

export const Subtitle = styled.div`
  font-size: 15px;
  color: #718a9f;
  font-weight: bold;
`;

export const HorizontalSplit = styled.div`
  height: 2px;
  background-color: rgb(238, 238, 238);
  margin-top: 5px;
  width: 100%;
`;

export const BranchsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
`;
