import styled, { css } from 'styled-components';
import { Card, Modal } from 'react-bootstrap';
import { MdOndemandVideo } from 'react-icons/md';

export const CustonDropdownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  justify-content: flex-start;
  color: #004d8d;
  white-space: nowrap;
`;

export const CustonDropdownIcon = styled(MdOndemandVideo)`
  margin-right: 8px;
  font-size: 16px;
`;

export const CustonDropdownText = styled.span`
  font-size: 14px;
`;

export const AccordionItem = styled(Card)`
  border: none;
  background: none;
`;

export const AccordionButton = styled(Card.Header)`
  padding: 0;
  border: none;
  background: none;
`;

export const AccordionBody = styled(Card.Body)`
  padding: 0;
  background: none;
  margin-left: 30px;
`;

export const CustomButton = styled.button`
  color: #a1a1a1;
  background: transparent;
  border: 0;
  display: flex;
  width: 100%;
  text-align: left;
  padding: ${(props) => (props.isCollapsed ? '8px 5px' : '8px 15px')};
  border-radius: 5px;
  align-items: end;
  justify-content: ${(props) =>
      props.isCollapsed ? 'center' : 'flex-start'};
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease,
    font-weight 0.3s ease;

  svg {
    margin-right: ${(props) => (props.isCollapsed ? '0' : '5px')};
    font-size: 24px;
    transition: font-size 0.3s ease;
  }

  span {
      transition: display 0.3s ease;
    }

  ${({ isHovered }) =>
    isHovered &&
    css`
      background: #f6f6f6;
      color: #004d8d;
      box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
      font-weight: bold;
    `}

  &:hover {
    background: #f6f6f6;
    color: #004d8d;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
    font-weight: bold;
  }
`;

export const StyledNav = styled.nav`
  display: flex;
`;

export const StyledUl = styled.ul`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: normal;
  gap: 5px;
  flex-direction: column;
  margin: ${(props) => (props.isCollapsed ? '0' : '5px 5px 0px 5px')};
`;

export const StyledModalHeader = styled(Modal.Header)`
  padding: 2px 16px;
`;


export const StyledModalFooter = styled(Modal.Footer)`
  padding: 2px 16px;
`;