import { type } from '@testing-library/user-event/dist/type';
import React, { createContext, useMemo, useState } from 'react';

const initialState = {
  searchValues: {},
  setSearchValues: () => {},
  reset: () => {},
};

const initialSearchValues = {
  admin: { state: '', text: '', type: 0 },
  org: { state: '', text: '', type: 0 },
  branch: { state: '', text: '', type: 0 },
  unit: { state: '', text: '', type: 0 },
  dep: { state: '', text: '', type: 0 },
};

export const ContextSearch = createContext(initialState);

const SearchProvider = ({ children }) => {
  const [searchValues, setSearchValues] = useState(initialSearchValues);

  const reset = () => {
    setSearchValues(initialSearchValues);
  };

  const exportData = useMemo(
    () => ({
      searchValues,
      setSearchValues,
      reset,
    }),
    [searchValues, setSearchValues]
  );

  return (
    <ContextSearch.Provider value={exportData}>
      {children}
    </ContextSearch.Provider>
  );
};

export default SearchProvider;
