import React from 'react';
import styled from 'styled-components';

const Image = styled.img``;

const NoImage = styled.div`
  text-align: center;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 1px #ccc dashed;
  border-radius: 10px;
  height: 100%;
  width: 90px;
`;

const OrgImage = ({ src, alt = 'prefeitura' }) => {
  if (src && alt) {
    return <Image src={src} alt={alt} />;
  }

  return <NoImage>sem imagem</NoImage>;
};

export default OrgImage;
