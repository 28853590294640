import React, { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import styled from 'styled-components';

const customIncreaseLegendSpacing = {
  id: 'increase-legend-spacing',
  beforeInit: (chart) => {
    const myChart = chart;
    const originalFit = myChart.legend.fit;
    myChart.legend.fit = function fit() {
      originalFit.bind(myChart.legend)();
      this.width += 50;
    };
  },
};

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  customIncreaseLegendSpacing,
);

const BoxChart = styled.div`
  width: 330px;
  height: 170px;
  min-width: calc(330px * 0.8);
  min-height: calc(170px * 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 470px) {
    width: calc(330px * 0.8);
    height: calc(170px * 0.8);
    flex-direction: column;
  }
`;

const ContainerChart = styled.div`
  display: flex;
  justify-content: center;
`;

const Graph = ({ values }) => {
  const data = {
    labels: ['Baixo Risco', 'Médio risco', 'Alto Risco', 'Indefinido'],
    datasets: [
      {
        data: values,
        backgroundColor: ['#bae6be', '#bdd4f8', '#ffb2b2', '#e0e0e0'],
        borderColor: ['#22af30', '#1f6fe8', '#ff3634', '#8c8c8c'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      customIncreaseLegendSpacing,
      legend: {
        labels: {
          usePointStyle: true,
          padding: 20,
          fullSize: false,
          boxWidth: 30,
          boxHeight: 30,
        },
        position: 'left',
        align: 'center',
      },
      datalabels: {
        color: ['#22af30', '#1f6fe8', '#ff3634', '#8c8c8c'],
        font: {
          weight: 'normal',
          size: 12,
        },
        formatter: (value, ctx) => {
          let sum = 0;
          const dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          const percentageCalc = (value * 100) / sum;
          const percentage =
            percentageCalc > 0 ? `${percentageCalc.toFixed(0)}%` : null;

          return percentage;
        },
      },
    },
  };

  return (
    <ContainerChart>
      <BoxChart>
        <Pie data={data} options={options} />
      </BoxChart>
    </ContainerChart>
  );
};

export default Graph;
