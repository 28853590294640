import styled from 'styled-components';

export const StyledElement = styled.li`
  @media (max-width: 470px) {
    ${(props) => {
      if (!props.isLogout) {
        return `
          display: none;
        `;
      }
      return '';
    }}
  }

  button {
    color: #a1a1a1;
    background: transparent;
    border: 0;
    display: flex;
    width: 100%;
    text-align: left;
    padding: ${(props) => (props.isCollapsed ? '8px 5px' : '8px 15px')};
    border-radius: 5px;
    align-items: end;
    justify-content: ${(props) =>
      props.isCollapsed ? 'center' : 'flex-start'};
    transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease,
      font-weight 0.3s ease;

    svg {
      margin-right: ${(props) => (props.isCollapsed ? '0' : '5px')};
      font-size: 24px;
      transition: font-size 0.3s ease;
    }

    span {
      transition: display 0.3s ease;
    }

    ${(props) => {
      if (props.isHovered) {
        return `
        background: #f6f6f6;
        color: #004d8d;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
        font-weight: bold;
        `;
      }
    }}

    ${(props) =>
      props.isActive &&
      `
      :hover {
        background: #f6f6f6;
        color: #004d8d;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
        font-weight: bold;
      }
    `}

    ${(props) => {
      if (props.isLogout) {
        return `
          border-top: 1px solid #eee;
          color: #bf7272;

          :hover {
            background: #f6f6f6;
            color: #dc3545;
            box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
            font-weight: bold;
          }         
        `;
      }
    }}
  }
`;